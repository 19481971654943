import React, { useContext, useState } from 'react';
import { ScheduleNotification } from './ScheduleNotification';
import { EditNotifications } from './EditNotifications';
import { CreateCode } from './CreateCode';
import { CreatePromo } from './CreatePromo';
import { CodeRedemptions } from './CodeRedemptions';
import { useNavigate } from 'react-router-dom';

import { Container, SidePanel, MenuItem, Content } from './styles';
import { AppContext } from '../../App';
import { UnscheduleNotification } from './UnscheduleNotification';
import { TestDiversifiedNotifications } from './TestDiversifiedNotifications';
import { MobileConfigViewer } from './MobileConfigViewer';

const ACTIONS = {
  GIFT: 'GIFT',
  PROMO: 'PROMO',
  NOTIFICATION: 'NOTIFICATION',
  MOBILE_CONFIG: 'MOBILE_CONFIG',
  EDIT_NOTIFICATIONS: 'EDIT_NOTIFICATIONS',
  CHECK_REDEMPTIONS: 'CHECK_REDEMPTIONS',
  TEST_DIVERSIFIED_NOTIFICATIONS: 'TEST_DIVERSIFIED_NOTIFICATIONS',
  UNSCHEDULE_NOTIFICATION: 'UNSCHEDULE_NOTIFICATION',
  LOGOUT: 'LOGOUT'
};

const AdminPortal: React.FC = () => {
  const [currentAction, setCurrentAction] = useState<string>(ACTIONS.GIFT);
  const { app } = useContext(AppContext);

  const navigate = useNavigate();
  if (!app) {
    return <Container />;
  }

  const renderContent = () => {
    switch (currentAction) {
      case ACTIONS.GIFT:
        return <CreateCode />;
      case ACTIONS.PROMO:
        return <CreatePromo />;
      case ACTIONS.CHECK_REDEMPTIONS:
        return <CodeRedemptions />;
      case ACTIONS.TEST_DIVERSIFIED_NOTIFICATIONS:
        return <TestDiversifiedNotifications />;
      case ACTIONS.NOTIFICATION:
        return <ScheduleNotification />;
      case ACTIONS.MOBILE_CONFIG:
        return <MobileConfigViewer />;
      case ACTIONS.EDIT_NOTIFICATIONS:
        return <EditNotifications />;
      case ACTIONS.UNSCHEDULE_NOTIFICATION:
        return <UnscheduleNotification />;
      default:
        return <CreateCode />;
    }
  };
  return (
    <Container>
      <SidePanel>
        <MenuItem
          onClick={() => {
            setCurrentAction(ACTIONS.GIFT);
          }}>
          Create Gift Codes
        </MenuItem>
        <MenuItem
          onClick={() => {
            setCurrentAction(ACTIONS.PROMO);
          }}>
          Create Promo Codes
        </MenuItem>
        <MenuItem
          onClick={() => {
            setCurrentAction(ACTIONS.CHECK_REDEMPTIONS);
          }}>
          Check Code Redemptions
        </MenuItem>
        <MenuItem
          onClick={() => {
            setCurrentAction(ACTIONS.TEST_DIVERSIFIED_NOTIFICATIONS);
          }}>
          Test Notifications
        </MenuItem>
        <MenuItem
          onClick={() => {
            setCurrentAction(ACTIONS.MOBILE_CONFIG);
          }}>
          View Mobile Config
        </MenuItem>
        <MenuItem
          onClick={() => {
            setCurrentAction(ACTIONS.NOTIFICATION);
          }}>
          Schedule Mass Notfication
        </MenuItem>
        <MenuItem
          onClick={() => {
            setCurrentAction(ACTIONS.UNSCHEDULE_NOTIFICATION);
          }}>
          Unschedule Mass Notfication
        </MenuItem>
        <MenuItem
          onClick={() => {
            setCurrentAction(ACTIONS.EDIT_NOTIFICATIONS);
          }}>
          Edit Mass Notfication
        </MenuItem>
        <MenuItem
          onClick={() => {
            app.currentUser?.logOut();
            navigate('/admin/login');
            window.location.reload();
          }}>
          Logout
        </MenuItem>
      </SidePanel>
      <Content>{renderContent()}</Content>
    </Container>
  );
};

export { AdminPortal };
