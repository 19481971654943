import styled from 'styled-components';

export const Container = styled.div`
  width: min(380px, 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  width: 112px;
  height: 112px;
`;

export const Title = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 50px;
  text-align: center;
  color: #073d59;
  @media (min-width: 1000px) {
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const Description = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 33px;
  text-align: center;
  color: #1e293b;
`;
