import React, { useContext, useState } from 'react';
import { Credentials } from 'realm-web';
import { useNavigate } from 'react-router-dom';
import { AppContext, User } from '../../App';
import { Form, Container, Input, SubmitInput, Error } from './styles';

const SignIn: React.FC = () => {
  const { app, setUser } = useContext(AppContext);
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();
  if (!app) {
    return <Container />;
  }
  return (
    <Container>
      <Form
        onSubmit={(e: React.SyntheticEvent) => {
          e.preventDefault();
          setError('');
          const target = e.target as typeof e.target & {
            email: { value: string };
            password: { value: string };
          };
          const email = target.email.value;
          const password = target.password.value;
          const credentials = Credentials.emailPassword(email, password);
          app
            ?.logIn(credentials)
            .then((user: User) => {
              user
                .callFunction('utils/authorizeAdminUser')
                .then(() => {
                  setUser(user);
                  navigate('/admin/portal');
                })
                .catch((e: any) => {
                  if (e.error.message) {
                    setError(e?.error.message);
                  } else {
                    setError(e?.error);
                  }
                  user.logOut();
                });
            })
            .catch((e: any) => {
              setError(e?.error);
            });
        }}>
        {error.length > 0 && <Error>{error}</Error>}
        <Input placeholder="Email" type="email" name="email" />
        <Input placeholder="Password" type="password" name="password" />
        <SubmitInput type="submit" value="Log in" />
      </Form>
    </Container>
  );
};

export { SignIn };
