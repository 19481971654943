import styled from 'styled-components';

export const Container = styled.div`
  min-height: 850px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const UpperContainer = styled.div<{ isMobile: boolean }>`
  flex: 0.6;
  padding: ${(props) => (props.isMobile ? '100px 50px' : '100px 150px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 2s ease;
`;

export const Title = styled.div`
  margin-bottom: 80px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 66px;
  text-align: center;
  color: #073d59;
`;

export const SubTitle = styled.div`
  margin-bottom: 50px;
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  color: #101828;
`;

export const Text = styled.div`
  align-self: start;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #073d59;
`;

export const HiddenText = styled.div`
  margin-bottom: 20px;
  display: none;
  transition: all 2s ease;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #374151;
`;

export const ReadMoreButton = styled.button`
  align-self: start;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #073d59;
  background: white;
  outline: none;
  border: none;
  cursor: pointer;
`;

export const WishListContainer = styled.div<{ isMobile: boolean }>`
  flex: 0.4;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: ${(props) => (props.isMobile ? '0 50px 100px 50px' : '0 76px 100px 76px')};
`;

export const RightSide = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => (props.isMobile ? 'center' : '')};
`;

export const DownloadText = styled.div`
  margin-bottom: 12px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  color: #073d59;
`;

export const DownloadLinksContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
`;

export const DownloadImage = styled.img`
  max-width: 100%;
`;

export const TryText = styled.div`
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #073d59;
`;
