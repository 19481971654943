import styled from 'styled-components';

export const LeaderboardContainer = styled.div`
  display: flex;
  min-height: 67vh;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  background-color: #231f20;
  width: 100%;
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 5em;
  color: #fff;
  @media (max-width: 800px) {
    font-size: 2.25em;
  }
`;

export const DescriptionTitle = styled.span`
  white-space: nowrap;
  font-weight: bold;
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const Description = styled.p`
  text-align: left;
  padding: 0 50px;
  color: #fff;
  margin-bottom: 5px;
  font-size: 1.25em;
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const DesktopDescriptionTitle = styled(DescriptionTitle)`
  display: inline;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const DesktopDescription = styled(Description)`
  display: block;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Text = styled.h3`
  color: #fff;
  text-align: center;
  padding: 0 10px;
`;

export const LeaderboardText = styled(Text)`
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: none;
  @media (min-width: 1024px) {
    display: flex;
  }
`;

export const Button = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1.5% 0 1.5%;
  margin: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  min-height: 40px;
  border-radius: 50px;
  color: #fff;
  border: 1px solid #f33;
  cursor: pointer;
  text-decoration: none;

  @media (min-width: 800px) {
    width: 30%;
  }

  background: rgb(247, 160, 48);
  background: linear-gradient(90deg, rgba(247, 160, 48, 1) 0%, rgba(235, 93, 48, 1) 100%);

  &:hover {
    transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;

    background: rgb(51, 204, 255);
    background: linear-gradient(90deg, rgba(51, 204, 255, 1) 0%, rgba(102, 51, 153, 1) 100%);
    border: 1px solid #3485b2;
  }
`;

export const LeaderboardName = styled.span`
  display: inline;
  font-weight: bold;
  padding: 0 5px;
`;

export const LeaderboardButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const MobileDownloadButton = styled(Button)`
  display: none;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1024px) {
    display: flex;
  }
`;

export const QrCode = styled.img`
  width: 250px;
  height: 250px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
`;

export const LeaderboardQrCode = styled(QrCode)`
  margin-bottom: 20px;
  display: none;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 1024px) {
    display: flex;
  }
`;
