import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../App';
import { Form, Input, SubmitInput, Error, Success, Select } from './styles';

const CreatePromo: React.FC = () => {
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');

  const handleYesClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setError('');
    const target = e.target as typeof e.target & {
      extensionType: { value: string };
      date: { value: string };
      promotionName: { value: string };
      maxRedemption: { value: number };
    };
    const extensionType = target.extensionType.value;
    const date = target.date.value;
    const promotionName = target.promotionName.value;
    const maxRedemption: number = +target.maxRedemption.value;
    if (isNaN(maxRedemption)) {
      alert('Please provide a proper integer as the value for Max Redemptions');
    }
    const validUntil = new Date(date).getTime();

    if (user != null) {
      if (!extensionType || !validUntil || !promotionName || !maxRedemption) {
        alert('Invalid input for promo codes');
        return;
      }
      user
        .callFunction('referralsAndPromoCodes/generatePromoCode', {
          extensionType,
          promotionName,
          maxRedemption,
          validUntil
        })
        .then((res) => {
          setSuccess(`Successfully created code/s:\n${JSON.stringify(res)}`);
        })
        .catch((e: any) => {
          setError(e?.error);
        });
    } else {
      navigate('/admin/login');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleNoClick = () => {};

  const handleAlert = (e: React.SyntheticEvent) => {
    const confirmed = window.confirm('Are you sure you want to create codes?');
    if (confirmed) {
      handleYesClick(e);
    } else {
      handleNoClick();
    }
  };
  return (
    <Form onSubmit={handleAlert}>
      <label htmlFor="extensionType">Type of the promotion extension</label>
      <Select id="extensionSelector" name="extensionType">
        <option value="WEEKLY">Weekly</option>
        <option value="MONTHLY">Monthly</option>
        <option value="ANNUALLY">Annually</option>
      </Select>
      <label htmlFor="date">Valid until date</label>
      <Input placeholder="Valid until date" type="date" name="date" />
      <label htmlFor="promotionName">Name of the promotion code :</label>
      <Input placeholder="Promo Name" name="promotionName" />
      <label htmlFor="maxRedemption">Maximum number of people this promotion is valid for :</label>
      <Input placeholder="Maximum number of people who can redeem" name="maxRedemption" />
      <SubmitInput type="submit" value="Generate" />
      {error.length > 0 && <Error>{error}</Error>}
      {success.length > 0 && <Success>{success}</Success>}
    </Form>
  );
};

export { CreatePromo };
