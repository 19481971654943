import React, { useState } from 'react';
import InputRange from 'react-input-range';
import { TrackingService } from '../../utils/trackingService';
import { GoalsIds } from '../../utils/constants';
import {
  Container,
  Title,
  QuestionsContainer,
  SliderContainer,
  SliderTitle,
  BoldText,
  SliderFooter,
  SliderFooterText,
  ContinueButton,
  ContinueButtonText
} from './styles';
import 'react-input-range/lib/css/index.css';
import './styles.css';

interface Props {
  selectedGoalId: string;
  onContinue: () => void;
}

const CommonFooter = () => (
  <SliderFooter>
    <SliderFooterText>This is not me</SliderFooterText>
    <SliderFooterText>This is me</SliderFooterText>
  </SliderFooter>
);

const sliderTitles: Record<string, any> = {
  [GoalsIds.PRAYERS]: [
    <SliderTitle key={1}>
      I understand <BoldText>Why</BoldText> we pray
    </SliderTitle>,
    <SliderTitle key={2}>
      I am happy with the <BoldText>consistency</BoldText> of my prayer
    </SliderTitle>,
    <SliderTitle key={3}>
      I am happy with the <BoldText>quality</BoldText> of my prayer
    </SliderTitle>
  ],
  [GoalsIds.QURAN]: [
    <SliderTitle key={1}>
      I understand the <BoldText>purpose and importance</BoldText> the Quran
    </SliderTitle>,
    <SliderTitle key={2}>
      I am happy with my <BoldText>consistency</BoldText> in reading the Quran
    </SliderTitle>,
    <SliderTitle key={3}>
      I am happy with my <BoldText>understanding</BoldText> of the Quran
    </SliderTitle>
  ],
  [GoalsIds.CONNECTION]: [
    <SliderTitle key={1}>
      I understand the <BoldText>names and attributes</BoldText> of Allah
    </SliderTitle>,
    <SliderTitle key={2}>
      <BoldText>I consistently work</BoldText> on building a closer connection with Allah
    </SliderTitle>,
    <SliderTitle key={3}>
      I am happy with the <BoldText>current connection</BoldText> i feel to Allah
    </SliderTitle>
  ],
  [GoalsIds.HABITS]: [
    <SliderTitle key={1}>
      I understand <BoldText>the life and story</BoldText> of the Prophet, peace be upon him
    </SliderTitle>,
    <SliderTitle key={2}>
      I regularly <BoldText>think about and reflect on</BoldText> the life of the Prophet
    </SliderTitle>,
    <SliderTitle key={3}>
      I know and apply the <BoldText>habits of</BoldText> the Prophet
    </SliderTitle>
  ],
  [GoalsIds.BELIEFS]: [
    <SliderTitle key={1}>
      I understand <BoldText>Why</BoldText> we pray
    </SliderTitle>,
    <SliderTitle key={2}>
      I am happy with the <BoldText>consistency</BoldText> of my prayer
    </SliderTitle>,
    <SliderTitle key={3}>
      I am happy with the <BoldText>quality</BoldText> of my prayer
    </SliderTitle>
  ],
  [GoalsIds.GOAL]: [
    <SliderTitle key={1}>
      I understand <BoldText>Why</BoldText> we pray
    </SliderTitle>,
    <SliderTitle key={2}>
      I am happy with the <BoldText>consistency</BoldText> of my prayer
    </SliderTitle>,
    <SliderTitle key={3}>
      I am happy with the <BoldText>quality</BoldText> of my prayer
    </SliderTitle>
  ]
};

const DemoSecondScreen: React.FC<Props> = ({ selectedGoalId, onContinue }) => {
  const [reasonValue, setReasonValue] = useState<number>(50);
  const [consistencyValue, setConsistencyValue] = useState<number>(50);
  const [qualityValue, setQualityValue] = useState<number>(50);

  const Titles = sliderTitles[selectedGoalId];

  return (
    <Container>
      <Title>What is your starting point?</Title>
      <QuestionsContainer>
        <SliderContainer>
          {Titles[0]}
          <InputRange
            maxValue={100}
            minValue={0}
            formatLabel={() => ''}
            value={reasonValue}
            onChange={(value) => {
              setReasonValue(value as number);
            }}
            onChangeComplete={() => {
              TrackingService.track(`demo_second_screen_why_slide`);
            }}
          />
          <CommonFooter />
        </SliderContainer>
        <SliderContainer>
          {Titles[1]}
          <InputRange
            maxValue={100}
            minValue={0}
            formatLabel={() => ''}
            value={consistencyValue}
            onChange={(value) => {
              setConsistencyValue(value as number);
            }}
            onChangeComplete={() => {
              TrackingService.track(`demo_second_screen_consistency_slide`);
            }}
          />
          <CommonFooter />
        </SliderContainer>
        <SliderContainer>
          {Titles[2]}
          <InputRange
            maxValue={100}
            minValue={0}
            formatLabel={() => ''}
            value={qualityValue}
            onChange={(value) => {
              setQualityValue(value as number);
            }}
            onChangeComplete={() => {
              TrackingService.track(`demo_second_screen_quality_slide`);
            }}
          />
          <CommonFooter />
        </SliderContainer>
      </QuestionsContainer>
      <ContinueButton
        onClick={() => {
          TrackingService.track(`demo_second_screen_continue_clicked`);
          onContinue();
        }}>
        <ContinueButtonText>Continue</ContinueButtonText>
      </ContinueButton>
    </Container>
  );
};

export { DemoSecondScreen };
