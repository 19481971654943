import styled from 'styled-components';

export const BannerContainer = styled.div`
  width: 100%;
  height: 50px;
  background: rgb(247, 147, 30);
  background: linear-gradient(
    90deg,
    rgba(247, 147, 30, 1) 0%,
    rgba(242, 83, 52, 1) 50%,
    rgba(255, 0, 255, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    height: 40px;
    flex-direction: row;
  }
`;

export const BannerText = styled.p`
  color: #fff;
  font-family: Mulish;
  font-size: calc(12px + (18 - 12) * ((100vw - 300px) / (1600 - 300)));
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const BannerMobileText = styled.p`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    color: #fff;
    font-family: Mulish;
    font-size: calc(12px + (18 - 12) * ((100vw - 300px) / (1600 - 300)));
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 2%;
  }
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const Button = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 2%;
  padding: 8px 20px 8px 20px;
  background: #fff;
  border: none;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  height: 27px;

  @media (max-width: 768px) {
    padding: 0 8px;
  }
`;

export const LearnMoreText = styled.span`
  display: inline-flex;
  color: #fff;
  font-family: Mulish;
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  padding: 0 16px;
  text-decoration: underline;
  cursor: pointer;
`;

export const ButtonText = styled.p`
  display: inline-flex;
  color: #f7367c;
  font-family: Mulish;
  font-style: normal;
  font-size: 14px;
  font-weight: 800;
  line-height: normal;

  @media (max-width: 768px) {
    font-size: 11px;
    display: inline;
    white-space: nowrap;
  }
`;

export const Icon = styled.img`
  max-height: 100%;
`;
