import styled from 'styled-components';

export const ResetContainer = styled.div`
  display: flex;
  min-height: 67vh;
  flex-direction: column;
  flex: 1;
  align-items: center;
  background-color: #231f20;
  width: 100%;
`;

export const Title = styled.h1`
  padding: 0 10px;
  color: #fff;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
`;

export const Label = styled.label`
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px;
`;

export const Input = styled.input`
  margin: 10px 0;
  padding: 5px 12px;
  font-size: 14px;
  line-height: 20px;
  color: #24292e;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #f33;
  border-radius: 50px;
  outline: none;
  box-shadow: rgba(225, 228, 232, 0.2) 0px 1px 0px 0px inset;
  :focus {
    border-color: #3485b2;
    outline: none;
    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  }
`;

export const SubmitButton = styled.input`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1.5% 0 1.5%;
  width: 60%;
  min-height: 40px;
  border: 1px solid #f33;
  border-radius: 50px;
  cursor: pointer;
  color: #fff;

  background: rgb(247, 160, 48);
  background: linear-gradient(90deg, rgba(247, 160, 48, 1) 0%, rgba(235, 93, 48, 1) 100%);

  &:hover {
    transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;

    background: rgb(51, 204, 255);
    background: linear-gradient(90deg, rgba(51, 204, 255, 1) 0%, rgba(102, 51, 153, 1) 100%);
    border: 1px solid #3485b2;
  }
`;

export const Text = styled.h3`
  padding: 0 10px;
  color: #fff;
`;

export const ErrorText = styled.h4`
  padding: 0 10px;
  color: red;
`;
