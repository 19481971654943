import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TrackingService } from '../../utils/trackingService';
import logo from '../../assets/logo.svg';
import { Container, Logo, ButtonText, RightSide, MainButton } from './styles';

interface Props {
  onClose?: () => void;
  showAction?: boolean;
  onLearnMore?: () => void;
}

const Header: React.FC<Props> = ({ showAction = true, onLearnMore }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <Logo
        onClick={() => {
          TrackingService.track('header_logo_clicked');
          navigate('/');
        }}
        src={logo}
        alt="Guider"
      />
      <RightSide>
        {/* <MainButton
          style={!showAction ? { visibility: 'hidden' } : {}}
          onClick={() => {
            TrackingService.track('header_donate_clicked');
            navigate('donation');
          }}>
          <ButtonText>Donate</ButtonText>
        </MainButton> */}
        <MainButton
          style={!showAction ? { visibility: 'hidden' } : {}}
          onClick={() => {
            TrackingService.track('header_learn_more_clicked');
            onLearnMore?.();
          }}>
          <ButtonText>Learn more</ButtonText>
        </MainButton>
      </RightSide>
    </Container>
  );
};

export { Header };
