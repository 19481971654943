import React, { useEffect } from 'react';
import { PageHOC } from '../../components';
import { PrivacyContainer, Title, Text, BoldText, Email } from './styles';

const EmailSpan = ({ children }: { children: string }) => (
  <Email
    onClick={() => {
      window.location.href = 'mailto:connect@theguiderapp.com';
    }}>
    {children}
  </Email>
);

const Privacy: React.FC = () => {
  useEffect(() => {
    window.location.href = 'https://niyyah.app/privacy-policy/';
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <PageHOC showAction={false}>
      <PrivacyContainer>
        <Title>Guider - privacy policy</Title>
        <Text>
          Guider App Ltd (the “Company”) respects the privacy concerns of the users of its website
          and app, and the services provided therein (the “Site”). The Company thus provides this
          privacy statement to explain what information is gathered during a visit to the Site and
          how such information may be used.
        </Text>
        <Text>
          <BoldText>Use of Information:</BoldText> As a general policy, no personally identifiable
          information, such as your name, address, or e-mail address, is automatically collected
          from your visit to the Site. However, certain non-personal information is recorded by the
          standard operation of the Company’s internet servers. Information such as the type of
          browser being used, its operating system, and your IP address is gathered in order to
          enhance your online experience.
        </Text>
        <Text>
          The Site’s various mailing lists, downloads, special offers, contests, registration forms,
          and surveys may request that you give us contact information such as your name, mailing
          and/or e-mail address, demographic information such as your age and gender, and personal
          preference information such as your preferred software and interests. Information
          submitted at the time of submission will be used by the Company only as necessary for our
          legitimate business interests, including without limitation the improvement of our
          products, services and the contents of the Site. The Company may also share such
          information with our business partners to further those interests. The Company will only
          share such information with our promotional partners with your explicit and additional
          consent. Personally identifiable information is never sold or leased to any third parties.
        </Text>
        <Text>
          With your permission, we may use your contact information to send you information about
          our company and products. You may always opt-out of receiving future mailings as provided
          below. The Company does not store any credit card information it may receive in regard to
          a specific transaction and/or billing arrangement except as necessary to complete and
          satisfy its rights and obligations with regard to such transaction, billing arrangement,
          and/or as otherwise authorized by a user.
        </Text>
        <Text>
          The Company may disclose user information in special cases when we have reason to believe
          that disclosing this information is necessary to identify, contact or bring legal action
          against someone who may be causing injury to or interference (either intentionally or
          unintentionally) with the Company’s rights or property, other users of the Site, or anyone
          else that colid be harmed by such activities.
        </Text>
        <Text>
          The Company may also be required to disclose personal information in response to lawfli
          requests by public authorities, including to meet national security or law enforcement
          requirements.
        </Text>
        <Text>
          <BoldText>Children Age 16 and Under:</BoldText> The Company recognizes the special
          obligation to protect personally identifiable information obtained from children age 16
          and under. AS SUCH, IF YOU ARE 16 YEARS OLD OR YOUNGER, THE COMPANY REQUESTS THAT YOU NOT
          SUBMIT ANY PERSONAL INFORMATION TO THE SITE OR TO THE COMPANY. If the Company discovers
          that a child age 16 or younger has signed up on the Site or provided us with personally
          identifiable information, we will delete that child’s identifiable information from our
          records.
        </Text>
        <Text>Here are a few tips to help make a child’s online experience safer:</Text>
        <ul>
          <li>
            <Text>
              Teach kids never to give personal information, unless supervised by a parent or
              responsible adlit. Includes name, address, phone, school, etc.
            </Text>
          </li>
          <li>
            <Text>Know the sites your kids are visiting and which sites are appropriate.</Text>
          </li>
          <li>
            <Text>
              Look for Website privacy policies. Know how your child’s information is treated.
            </Text>
          </li>
        </ul>
        <Text>
          <BoldText>Use of Cookies:</BoldText> Cookies are pieces of information that a website
          transfers to an individual’s computer hard drive for record keeping purposes. Cookies make
          using our Site easier by, among other things, saving your passwords and preferences for
          you. These cookies are restricted for use only on our Site, and do not transfer any
          personal information to any other party. Most browsers are initially set up to accept
          cookies. You can, however, reset your browser to refuse all cookies or indicate when a
          cookie is being sent. Please consult the technical information relevant to your browser
          for instructions. If you choose to disable your cookies setting or refuse to accept a
          cookie, some parts of the Site may not function properly or may be considerably slower.
        </Text>
        <Text>
          <BoldText>Malware/Spyware/Viruses:</BoldText> Neither the Company nor the Site knowingly
          permit the use of malware, spyware, viruses, and/or other similar types of software.
        </Text>
        <Text>
          <BoldText>Links to External Sites:</BoldText> The Company is not responsible for the
          content or practices of third party websites that may be linked to the Site. The Company
          is also not responsible for any information that you might share with such linked
          websites. You should refer to each website’s respective privacy policy and practices prior
          to disclosing any information.
        </Text>
        <Text>
          <BoldText>Bulletin Boards and Chat Areas:</BoldText> Guests of the Site are solely
          responsible for the content of messages they post on the Company’s forums, such as chat
          rooms and bulletin boards. Users should be aware that when they voluntarily disclose
          personal information (e.g., user name, e-mail address, phone number) on the bulletin
          boards or in the chat areas, that information can be collected and used by others and may
          result in unsolicited messages from other people. You are responsible for the personal
          information you choose to submit in these instances. Please take care when using these
          features.
        </Text>
        <Text>
          <BoldText>Choice/Opt-Out:</BoldText> The Site may provide you the opportunity to opt-in to
          receive communications from us at the point where we request information about you. You
          always have the option of removing your name from any e-mail list in order to discontinue
          any such future communications. In order to ensure immediate removal from any list, please
          follow the specific instructions set forth within the communications you receive from the
          Company which you no longer wish to receive. If you are unsuccessful in completing the
          instructions specified in any such communication, please e-mail us at{' '}
          <EmailSpan>connect@theguiderapp.com</EmailSpan>, including a copy of the undesired email
          attached to the request, and state you wish to be removed from the mailing list.
        </Text>
        <Text>
          <BoldText>Transfer of Information Across National Borders:</BoldText> Our site and various
          information we collect are operated on servers located in various jurisdictions, including
          the United States. When you access or use the Site and/or our services, personal
          information about you may be transferred outside the country in which you are situated to
          these other locations. The Company’s policies ensure that such personal information is
          protected to the same standard when processed by any Company entity or office around the
          world. We also ensure that appropriate contracts containing standard data protection
          clauses approved by the European Commission to protect that information and the rights of
          individuals are in place with any and all third-party service providers we may use.
        </Text>
        <Text>
          <BoldText>
            Your Access to and Control Over Your Personally Identifiable Information:
          </BoldText>
          At any time, but only once per calendar year, or as otherwise required under applicable
          law, users may contact Company to review the personally identifiable information that
          Company has collected about you. If you discover any errors, please notify Company and the
          information will be corrected. To review the personally identifiable information that
          company has collected about you, please send an email to{' '}
          <EmailSpan>connect@theguiderapp.com</EmailSpan> with the subject line: “Personal
          Information Review Request.” Users may also request that Company delete a user account(s)
          or, if you have not established a user account, your email address, and any related data
          at any time. If you wish to delete your user account(s), please email us at{' '}
          <EmailSpan>connect@theguiderapp.com</EmailSpan> with the words “Delete Account” in the
          subject line. If you do not have a user account and wish to delete your email address or
          other personally identifiable information that you might have provided through your use of
          the Site, any Games, and/or any Services, please email us at{' '}
          <EmailSpan>connect@theguiderapp.com</EmailSpan> with the words “Delete My Information” in
          the subject line.
        </Text>
        <Text>
          You may also choose to confirm that the Company does not use your personal information in
          certain ways and/or to otherwise “opt out” of certain uses of that personal information,
          including without limitation (i) when your personal may to be disclosed to a third party
          unrelated to the Company and/or parties directly related to providing your Services and/or
          (ii) when your personal information may be used for a purpose that is materially different
          from the purpose(s) for which it was originally collected or subsequently authorized by
          you. If you wish to limit your personal data in either such way, or have other questions
          about how the Company may use your personal data, please contact us at{' '}
          <EmailSpan>connect@theguiderapp.com</EmailSpan> with the words “Privacy Request” in the
          subject line.
        </Text>
        <Text>
          <BoldText>Contact Information for Complaints or Concerns:</BoldText> If you have any
          complaints or concerns about the Company or about this privacy statement, please contact:
        </Text>
        <Text style={{ alignSelf: 'start' }}>
          Via email: <EmailSpan>connect@theguiderapp.com</EmailSpan>
        </Text>
        <Text>
          Information provided by you via general e-mail inquiries to the Company such as your
          e-mail address is used only to respond to your inquiries in the ordinary course of
          business, and is never shared with third parties.
        </Text>
        <Text>
          If you are a resident of the EU and have an unresolved data privacy concern or personal
          information collection, use, or disclosure concern, you may file a complaint/inquiry with
          us using the details above.
        </Text>
        <Text>
          <BoldText>Security/How Your Personally Identifiable Information Is Protected:</BoldText>{' '}
          Security for all personally identifiable information is extremely important to us. We have
          implemented technical, administrative and physical security measures to attempt to protect
          your personally identifiable information from unauthorized access and improper use. We
          also protect your personally identifiable information offline. Only employees who need the
          information to perform a specific job (for example, customer service) are granted access
          to personally identifiable information. The computers/servers in which we store personally
          identifiable information are kept in a secure environment. We continually review all such
          measures and update them when appropriate. Unfortunately, no data transmission over the
          Internet can be guaranteed to be 100% secure. As a result, while we strive to protect your
          personal information, Company cannot ensure or warrant the security of any information you
          transmit via the internet. By transmitting any such information to Company, you accept
          that you do so at your own risk.
        </Text>
        <Text>
          <BoldText>Your Acceptance of These Terms:</BoldText> By using the Site, you accept the
          policies and restrictions set forth in this Online Privacy Policy. If you do not agree to
          this policy, please do not use the Site. This Online Privacy Policy may be revised from
          time to time by updating this posting. You are bound by any such revisions and should
          therefore periodically visit this page to review the then current Online Privacy Policy to
          which you are bound.
        </Text>
        <Text>Last Updated: Feb 24, 2023 </Text>
      </PrivacyContainer>
    </PageHOC>
  );
};

export { Privacy };
