import React from 'react';
import quoteImg from '../../assets/quote-green.png';
import whiteQuoteImg from '../../assets/quote.png';
import {
  Container,
  InfoContainer,
  LeftSide,
  Avatar,
  NameTitle,
  Title,
  Quote,
  Name,
  TestimonialText
} from './styles';

interface Props {
  name: string;
  title: string;
  text: string;
  avatar: any;
  isSelected: boolean;
}

const Testimonial: React.FC<Props> = ({ name, title, text, avatar, isSelected = false }) => {
  return (
    <Container style={{ backgroundColor: isSelected ? '#073d59' : '#fdfbf5' }}>
      <InfoContainer>
        <LeftSide>
          <Quote src={isSelected ? whiteQuoteImg : quoteImg} />
          <NameTitle>
            <Name style={{ color: isSelected ? '#ffffff' : '#115F6A' }}>{name}</Name>
            <Title style={{ color: isSelected ? '#ffffff' : '#115F6A' }}>{title}</Title>
          </NameTitle>
        </LeftSide>
        <Avatar src={avatar} />
      </InfoContainer>
      <TestimonialText style={{ color: isSelected ? '#ffffff' : '#115F6A' }}>
        {text}
      </TestimonialText>
    </Container>
  );
};

export { Testimonial };
