import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #073d59;
`;

export const Form = styled.form`
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Input = styled.input`
  margin: 10px;
  width: 200px;
  height: 30px;
`;

export const SubmitInput = styled.input`
  margin: 20px;
  width: 150px;
  height: 30px;
`;

export const Error = styled.div`
  color: red;
`;
