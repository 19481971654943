import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
`;

export const SidePanel = styled.div`
  flex: 0.3;
  height: 100%;
  padding: 10px;
  border-right: 1px solid #073d59;
`;

export const MenuItem = styled.button`
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  border: none;
  cursor: pointer;
  background-color: #073d59;
  color: white;
`;

export const Content = styled.div`
  flex: 0.7;
  padding: 20px;
`;

export const Form = styled.form`
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Input = styled.input`
  margin: 10px;
  width: 200px;
  height: 30px;
`;

export const Select = styled.select`
  margin: 10px;
  width: 200px;
  height: 30px;
`;

export const SubmitInput = styled.input`
  margin: 20px;
  width: 150px;
  height: 30px;
  background-color: #073d59;
  color: white;
`;

export const Error = styled.div`
  color: red;
`;

export const Success = styled.div`
  color: green;
`;
