import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../App';
import { Form, Input, SubmitInput, Error, Success } from './styles';

const UnscheduleNotification: React.FC = () => {
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');

  const handleYesClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setError('');
    const target = e.target as typeof e.target & {
      notificationId: { value: string };
    };

    const notificationIdValue = target.notificationId.value;
    if (!notificationIdValue) {
      alert('Invalid input for notificationId');
      return;
    }
    if (user != null) {
      try {
        const result = await user.callFunction('unscheduleMassNotification', {
          massNotificationId: notificationIdValue
        });
        setSuccess(`Notification disabled`);
      } catch (e: any) {
        setError(e);
      }
    } else navigate('/admin/login');
  };

  return (
    <Form onSubmit={handleYesClick}>
      <Input placeholder="Notification Id To disable" name="notificationId" />
      <SubmitInput type="submit" value="Disable Notification" />
      {error.length > 0 && <Error>{error}</Error>}
      {success.length > 0 && <Success>{success}</Success>}
    </Form>
  );
};

export { UnscheduleNotification };
