import React, { useState } from 'react';
import { useWindowWidthAndHeight } from '../../utils/windowSizeHook';
import { TrackingService } from '../../utils/trackingService';
import { validateEmail } from '../../utils/validations';
import {
  instagramLink,
  MAILCHIMP_SIGNUP_SOCIAL_TAG,
  MAILCHIMP_SIGNUP_HERO_TAG
} from '../../utils/constants';
import instagramIphone from '../../assets/instagramIphone.png';
import {
  Container,
  MobileContainer,
  LeftContainer,
  Title,
  InputContainer,
  Input,
  Button,
  MessageContainer,
  ButtonText,
  RightContainer,
  Image
} from './styles';

interface SocialProps {
  status: string;
  message: string;
  onValidated: ({ EMAIL, tags }: { EMAIL: string; tags: string }) => void;
  isEmailHighlighted: boolean;
}

const SocialScreen: React.FC<SocialProps> = ({
  status,
  message,
  onValidated,
  isEmailHighlighted
}) => {
  const [email, setEmail] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);

  const dimensions = useWindowWidthAndHeight();
  const isMobile = dimensions[0] <= 1000;

  const MainContainer = isMobile ? MobileContainer : Container;

  return (
    <MainContainer>
      <LeftContainer style={isMobile ? { margin: '80px 0' } : {}}>
        <Title isMobile={isMobile}>
          Sign up to receive latest product news,
          <br /> exclusive content and more!
        </Title>
        <InputContainer>
          <Input
            isMobile={isMobile}
            id={'social_email_input_field'}
            className={isEmailHighlighted ? 'highlight-overlay' : ''}
            onClick={() => {
              TrackingService.track('social_page_email_field_clicked');
            }}
            placeholder={'Enter your email'}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <Button
            isMobile={isMobile}
            onClick={() => {
              if (validateEmail(email)) {
                TrackingService.track('social_page_sign_up_clicked_with_valid_email');
                setShowError(false);
                onValidated({
                  EMAIL: email,
                  tags: `${MAILCHIMP_SIGNUP_SOCIAL_TAG},${MAILCHIMP_SIGNUP_HERO_TAG}`
                });
              } else {
                TrackingService.track('social_page_sign_up_clicked_with_Invalid_email');
                setShowError(true);
              }
            }}>
            <ButtonText>Sign up</ButtonText>
          </Button>
        </InputContainer>
        {status === 'error' && <MessageContainer isSuccess={false}>{message}</MessageContainer>}
        {status === 'success' && (
          <MessageContainer isSuccess>
            {'You have been subscribed to our newsletter. Thanks!'}
          </MessageContainer>
        )}
        {showError && (
          <MessageContainer isSuccess={false}>
            Email format is not valid - please try again
          </MessageContainer>
        )}
      </LeftContainer>
      <RightContainer style={{ justifyContent: isMobile ? 'center' : 'start' }}>
        <Image
          src={instagramIphone}
          onClick={() => {
            window.open(instagramLink, '_blank');
            return null;
          }}
        />
      </RightContainer>
    </MainContainer>
  );
};

export { SocialScreen };
