import styled from 'styled-components';

export const PrivacyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin: 0 20%;
`;

export const Title = styled.h1``;

export const Text = styled.h3``;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const Email = styled.span`
  cursor: pointer;
  color: blue;
`;
