import React, { useEffect, useState } from 'react';
import { Subscribe, PageHOC } from '../../components';
import { scrollToTargetAdjusted } from '../../utils/helpers';
import {
  HeroScreen,
  TestimonialsScreen,
  BenefitsScreen,
  SocialScreen,
  MeetGuiderScreen
} from '../../screens';

const Home: React.FC = () => {
  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  const [isEmailHighlighted, setIsEmailHighlighted] = useState<boolean>(false);

  return (
    <PageHOC
      showAction
      onLearnMore={() => {
        setIsEmailHighlighted(false);
        scrollToTargetAdjusted('social_email_input_field');
        setTimeout(() => {
          setIsEmailHighlighted(true);
        }, 1000);
        setTimeout(() => {
          setIsEmailHighlighted(false);
        }, 2500);
      }}>
      <>
        <HeroScreen />
        <TestimonialsScreen />
        <BenefitsScreen />
        <Subscribe WrappedComponent={SocialScreen} isEmailHighlighted={isEmailHighlighted} />
        <MeetGuiderScreen />
      </>
    </PageHOC>
  );
};

export { Home };
