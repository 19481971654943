import * as React from 'react';
import { Container, CustomLink } from './styles';

const Payment = () => {
  return (
    <Container>
      <stripe-pricing-table
        style={{
          width: '100%'
        }}
        pricing-table-id="prctbl_1MVdjHCyOA3YQbxepRzDzOwc"
        publishable-key="pk_live_51MUsz3CyOA3YQbxefkw62q5uVXxmFc2aHB1EyTU4mvFUoP4YtYepnzxkoXll7pN8MTtlsNnNANCBN91Dur2YElCf00EeVordGd"></stripe-pricing-table>

      <CustomLink href={'https://donate.stripe.com/4gw6pZdfr3Zj1bOaEE'} rel="noreferrer">
        Choose donation amount
      </CustomLink>
      <CustomLink
        target="_blank"
        href={'https://billing.stripe.com/p/login/28odRaaGJ62C6OYcMM'}
        rel="noreferrer">
        Manage donation
      </CustomLink>
    </Container>
  );
};

export { Payment };
