import styled from 'styled-components';

export const Container = styled.div`
  width: min(380px, 100%);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  width: 80px;
  height: 80px;
`;

export const Title = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
  text-align: center;
  color: #434344;
  @media (min-width: 1000px) {
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const Description = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #434344;
`;
