import React, { useEffect } from 'react';
import { TrackingService } from '../../utils/trackingService';
import {
  GoalsIds,
  MAILCHIMP_PRACTICAL_GUIDER_1_TAG,
  MAILCHIMP_PRACTICAL_GUIDER_2_TAG,
  MAILCHIMP_PRACTICAL_GUIDER_3_TAG,
  MAILCHIMP_PRACTICAL_GUIDER_4_TAG
} from '../../utils/constants';
import partyImg from '../../assets/demo/party.png';
import {
  Container,
  Image,
  Title,
  UpperContainer,
  ContinueButton,
  ContinueButtonText,
  Congrats
} from './styles';

interface Props {
  onContinue: () => void;
  email: string;
  selectedGoalId: string;
  onValidated: ({ EMAIL, tags }: { EMAIL: string; tags: string }) => void;
}

const SubscriptionTag: Record<string, number> = {
  [GoalsIds.PRAYERS]: MAILCHIMP_PRACTICAL_GUIDER_1_TAG,
  [GoalsIds.QURAN]: MAILCHIMP_PRACTICAL_GUIDER_2_TAG,
  [GoalsIds.CONNECTION]: MAILCHIMP_PRACTICAL_GUIDER_3_TAG,
  [GoalsIds.HABITS]: MAILCHIMP_PRACTICAL_GUIDER_4_TAG,
  [GoalsIds.GOAL]: MAILCHIMP_PRACTICAL_GUIDER_1_TAG
};

const DemoThirdScreen: React.FC<Props> = ({ onContinue, email, selectedGoalId, onValidated }) => {
  useEffect(() => {
    TrackingService.track(`demo_final_screen_reached`);
    onValidated({ EMAIL: email, tags: `${SubscriptionTag[selectedGoalId]}` });
  }, [email, onValidated, selectedGoalId]);
  return (
    <Container>
      <UpperContainer>
        <Image src={partyImg} />
        <Congrats>Congratulations!</Congrats>
        <Title>Check your inbox for your first practical guide</Title>
      </UpperContainer>
      <ContinueButton
        onClick={() => {
          TrackingService.track(`demo_last_screen_continue_clicked`);
          onContinue();
        }}>
        <ContinueButtonText>Return Home</ContinueButtonText>
      </ContinueButton>
    </Container>
  );
};

export { DemoThirdScreen };
