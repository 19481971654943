import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindowWidthAndHeight } from '../../utils/windowSizeHook';
import { TrackingService } from '../../utils/trackingService';
import { instagramLink, linkedInLink, tiktokLink } from '../../utils/constants';
import logo from '../../assets/logo.svg';
import scrollTop from '../../assets/scrollTop.png';
import {
  Container,
  Divider,
  UpperFooterContainer,
  UpperFooterMobileContainer,
  MobileUpperFooterLogoContainer,
  UpperFooterLogo,
  NavigationContatiner,
  MobileNavigationContatiner,
  NavigationItem,
  ContactUsContainer,
  MobileContactUsContainer,
  ContactUsText,
  ContactEmailText,
  ScrollTopWrapper,
  ScrollTop,
  LowerFooterContainer,
  CopyRightText,
  SocialMediaContatiner,
  TiktokIcon,
  InstagramIcon,
  LinkedInIcon
} from './styles';

const ScrollTopComponent = () => (
  <ScrollTopWrapper
    onClick={() => {
      TrackingService.track('footer_scroll_top_clicked');
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }}>
    <ScrollTop src={scrollTop} />
  </ScrollTopWrapper>
);

const Footer: React.FC = () => {
  const dimensions = useWindowWidthAndHeight();
  const isMobile = dimensions[0] <= 1000;

  const MobileUpperComponent = () => (
    <UpperFooterMobileContainer>
      <MobileUpperFooterLogoContainer>
        <UpperFooterLogo
          src={logo}
          onClick={() => {
            TrackingService.track('footer_logo_clicked');
            navigate('/');
          }}
        />
        <ScrollTopComponent />
      </MobileUpperFooterLogoContainer>
      <MobileNavigationContatiner>
        <NavigationItem
          isMobile={isMobile}
          onClick={() => {
            TrackingService.track('footer_home_link_clicked');
          }}
          to={'/'}>
          Home
        </NavigationItem>
        <NavigationItem
          isMobile={isMobile}
          onClick={() => {
            TrackingService.track('footer_privacy_policy_link_clicked');
          }}
          to={'/privacy'}>
          Privacy Policy
        </NavigationItem>
        <NavigationItem
          isMobile={isMobile}
          onClick={() => {
            TrackingService.track('footer_donate_link_clicked');
          }}
          to={'/donation/donate'}>
          Donate
        </NavigationItem>
      </MobileNavigationContatiner>
      <Divider />
      <MobileContactUsContainer>
        <ContactUsText>Contact us</ContactUsText>
        <ContactEmailText
          onClick={() => {
            window.location.href = 'mailto:connect@niyyah.app';
          }}>
          connect@niyyah.app
        </ContactEmailText>
        <div style={{ height: '20px' }} />
        <SocialMediaContatiner>
          <a
            href={tiktokLink}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              TrackingService.track('footer_tiktok_icon_clicked');
            }}>
            <TiktokIcon />
          </a>
          <a
            href={instagramLink}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              TrackingService.track('footer_instagram_icon_clicked');
            }}>
            <InstagramIcon />
          </a>
          <a
            href={linkedInLink}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              TrackingService.track('footer_linkedin_icon_clicked');
            }}>
            <LinkedInIcon />
          </a>
        </SocialMediaContatiner>
      </MobileContactUsContainer>
    </UpperFooterMobileContainer>
  );

  const MainUpperComponent = () => (
    <UpperFooterContainer>
      <UpperFooterLogo
        src={logo}
        onClick={() => {
          TrackingService.track('footer_logo_clicked');
          navigate('/');
        }}
      />
      <NavigationContatiner>
        <NavigationItem
          isMobile={isMobile}
          onClick={() => {
            TrackingService.track('footer_home_link_clicked');
          }}
          to={'/'}>
          Home
        </NavigationItem>
        <NavigationItem
          isMobile={isMobile}
          onClick={() => {
            TrackingService.track('footer_privacy_policy_link_clicked');
          }}
          to={'/privacy'}>
          Privacy Policy
        </NavigationItem>
        <NavigationItem
          isMobile={isMobile}
          onClick={() => {
            TrackingService.track('footer_donate_link_clicked');
          }}
          to={'/donation/donate'}>
          Donate
        </NavigationItem>
      </NavigationContatiner>
      <ContactUsContainer>
        <ContactUsText>Contact us</ContactUsText>
        <ContactEmailText
          onClick={() => {
            window.location.href = 'mailto:connect@niyyah.app';
          }}>
          connect@niyyah.app
        </ContactEmailText>
        <SocialMediaContatiner>
          <a
            href={tiktokLink}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              TrackingService.track('footer_tiktok_icon_clicked');
            }}>
            <TiktokIcon />
          </a>
          <a
            href={instagramLink}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              TrackingService.track('footer_instagram_icon_clicked');
            }}>
            <InstagramIcon />
          </a>
          <a
            href={linkedInLink}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              TrackingService.track('footer_linkedin_icon_clicked');
            }}>
            <LinkedInIcon />
          </a>
        </SocialMediaContatiner>
      </ContactUsContainer>
      <ScrollTopComponent />
    </UpperFooterContainer>
  );

  const navigate = useNavigate();

  return (
    <Container isMobile={isMobile}>
      {isMobile ? <MobileUpperComponent /> : <MainUpperComponent />}
      <Divider />
      <LowerFooterContainer>
        <CopyRightText isMobile={isMobile}>
          Copyright © 2022 Guider. All Rights Reserved.
        </CopyRightText>
      </LowerFooterContainer>
    </Container>
  );
};

export { Footer };
