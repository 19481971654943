import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../App';
import { Form, Input, SubmitInput, Error, Success } from './styles';

const ScheduleNotification: React.FC = () => {
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');

  const handleYesClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setError('');
    const target = e.target as typeof e.target & {
      text: { value: string };
      date: { value: string };
      hour: { value: number };
    };
    const text = target.text.value;
    const date = target.date.value;
    const hour = target.hour.value;
    if (user != null) {
      if (!text || !date || !hour || hour < 0) {
        alert('Invalid input for schedule notifications');
        return;
      }
      text !== '' &&
        date !== '' &&
        hour > 0 &&
        user.functions
          .scheduleMassNotification({ text, date, hour: Number(hour) })
          .then(() => {
            setSuccess('Successfully scheduled a mass notification');
          })
          .catch((e: any) => {
            setError(e?.error);
          });
    } else {
      navigate('/admin/login');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleNoClick = () => {};

  const handleAlert = (e: React.SyntheticEvent) => {
    const confirmed = window.confirm('Are you sure you want to create a MASS notification?');
    if (confirmed) {
      handleYesClick(e);
    } else {
      handleNoClick();
    }
  };
  return (
    <Form onSubmit={handleAlert}>
      <Input placeholder="Text" name="text" />
      <Input placeholder="Date" type="date" name="date" />
      <Input placeholder="Hour" type="number" min="0" max="23" name="hour" />
      <SubmitInput type="submit" value="Schedule" />
      {error.length > 0 && <Error>{error}</Error>}
      {success.length > 0 && <Success>{success}</Success>}
    </Form>
  );
};

export { ScheduleNotification };
