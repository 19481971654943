/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { CollapseItem } from '../../components';
import { useWindowWidthAndHeight } from '../../utils/windowSizeHook';
import {
  Container,
  UpperContainer,
  Title,
  SubTitle,
  LowerContainer,
  FAQContainer,
  Space
} from './styles';

const FAQScreen: React.FC = () => {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [width, height] = useWindowWidthAndHeight();
  const isMobile = width <= 1000;

  return (
    <Container isMobile={isMobile}>
      <UpperContainer isMobile={isMobile}>
        <Title>Frequently Asked Questions</Title>
        <SubTitle>You can also contact us below for more information.</SubTitle>
      </UpperContainer>
      <LowerContainer>
        <FAQContainer>
          <CollapseItem
            title={'How much does it cost to gift Guider?'}
            text={
              <>
                For teams of 100 or less, Guider's gift packages start at £10 per employee. For
                larger team sizes, please contact us directly at{' '}
                <a href={'mailto:connect@theguiderapp.com'} target="_blank" rel="noreferrer">
                  connect@theguiderapp.com
                </a>
              </>
            }
          />
        </FAQContainer>
        <Space />
        <FAQContainer>
          <CollapseItem
            title={'How do I gift Guider?'}
            text={
              <>
                You can purchase a gift package by selecting the 'Gift Guider' button at the top of
                this page. You will then receive a unique gift code that can be sent to your team
                with a personalised message from you.
              </>
            }
          />
        </FAQContainer>
        <Space />
        <FAQContainer>
          <CollapseItem
            title={'How do I apply for the scholarship?'}
            text={
              <>
                Once you have purchased a gift package, we will send you all of the relevant
                materials to share Guider access with your team via your preferred channel. We will
                provide hands-on support to handle any queries along the way.
              </>
            }
          />
        </FAQContainer>
      </LowerContainer>
    </Container>
  );
};

export { FAQScreen };
