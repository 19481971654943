import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: min(100%, 418px);
  height: 350px;
  border-radius: 16px;

  ${css`
    -webkit-user-select: none;
    -webkit-user-drag: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    user-drag: none;
  `}
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 41px 16px 24px;
`;

export const LeftSide = styled.div`
  display: flex;
`;

export const NameTitle = styled.div`
  padding-left: 20px;
`;

export const Avatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;

  ${css`
    -webkit-user-select: none;
    -webkit-user-drag: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    user-drag: none;
  `}
`;

export const Quote = styled.img`
  width: 50px;
  height: 50px;
`;

export const Name = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #073d59;
`;

export const Title = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #073d59;
`;

export const TestimonialText = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0 24px 24px 24px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #073d59;
`;
