import React, { useEffect, useState } from 'react';
import { PageHOC } from '../../components';
import { useLocation } from 'react-router-dom';
import {
  Description,
  DescriptionTitle,
  DesktopDescription,
  DesktopDescriptionTitle,
  LeaderboardButton,
  LeaderboardContainer,
  LeaderboardName,
  LeaderboardQrCode,
  LeaderboardText,
  MobileDownloadButton,
  QrCode,
  Text,
  Title
} from './styles';

const Leaderboard: React.FC = () => {
  const location = useLocation();
  const [groupId, setGroupId] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);
  const [showQrCode, setShowQrCode] = useState(false);
  const [leaderboardName, setLeaderboardName] = useState('');

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlGroupId = params.get('groupId');
    if (!urlGroupId) {
      return;
    }
    setGroupId(urlGroupId);
    setLeaderboardName(urlGroupId.replace(/([A-Z])/g, ' $1').split(/\d/)[0]);

    if (groupId) {
      window.location.href = `niyyah://groups?groupId=${urlGroupId}`;
    }
  }, [location.search, groupId]);

  return (
    <PageHOC showAction={false}>
      <LeaderboardContainer>
        <Title>What is your Niyyah?</Title>
        <Description>
          <DescriptionTitle>Step 1:</DescriptionTitle> Ensure Niyyah is downloaded
        </Description>
        <Description>
          <DescriptionTitle>Step 2:</DescriptionTitle> Once Niyyah is installed, click below to join
          the leaderboard
        </Description>
        <DesktopDescription>
          <DesktopDescriptionTitle>Step 1:</DesktopDescriptionTitle> Ensure Niyyah is downloaded on
          your phone
        </DesktopDescription>
        <DesktopDescription>
          <DesktopDescriptionTitle>Step 2:</DesktopDescriptionTitle> Once Niyyah is installed, scan
          the QR to join the leaderboard!
        </DesktopDescription>
        <>
          {showQrCode && (
            <QrCode
              src="https://api.qrserver.com/v1/create-qr-code/?size=500x500&data=https://onelink.to/9jsfjj"
              alt="Niyyah QR-Code"
              width="500px"
              height="500px"
            />
          )}
          {groupId && (
            <>
              <LeaderboardQrCode
                src={`https://api.qrserver.com/v1/create-qr-code/?size=500x500&data=niyyah://groups?groupId=${groupId}`}
              />
              <LeaderboardText>
                Please open this link on your phone or scan the QR-Code to join the{' '}
                <LeaderboardName>{leaderboardName}</LeaderboardName> leaderboard
              </LeaderboardText>
              <LeaderboardButton
                href={`niyyah://groups?groupId=${groupId}`}
                onClick={() => setLoading(true)}>
                Join the <LeaderboardName>{leaderboardName}</LeaderboardName> Leaderboard
              </LeaderboardButton>
              {loading && (
                <>
                  <Text>
                    If you&apos;re not redirected to the app,
                    <br />
                    simply download Niyyah first
                  </Text>
                  <MobileDownloadButton href="https://onelink.to/9jsfjj">
                    Download Niyyah
                  </MobileDownloadButton>
                </>
              )}
            </>
          )}
        </>
      </LeaderboardContainer>
    </PageHOC>
  );
};

export { Leaderboard };
