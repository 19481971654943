import React from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { PUBLIC_MAILCHIMP_URL } from '../../utils/constants';

const Subscribe: React.FC<{
  WrappedComponent: React.FC<any>;
  onContinue?: () => void;
  email?: string;
  selectedGoalId?: string;
  isEmailHighlighted?: boolean;
}> = ({ WrappedComponent, onContinue, email, selectedGoalId, isEmailHighlighted }) => {
  return (
    <MailchimpSubscribe
      url={PUBLIC_MAILCHIMP_URL}
      render={(props: any) => {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        const { subscribe, status, message } = props || {};
        return (
          <WrappedComponent
            onContinue={onContinue}
            email={email}
            selectedGoalId={selectedGoalId}
            isEmailHighlighted={isEmailHighlighted}
            status={status}
            message={message}
            onValidated={(formData: any) => subscribe(formData)}
          />
        );
      }}
    />
  );
};

export { Subscribe };
