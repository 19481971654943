import React from 'react';
import { Container, Image, Title, Description } from './styles';

interface Props {
  image: string;
  title: string;
  description: string;
}

const Benefit: React.FC<Props> = ({ image, title, description }) => {
  return (
    <Container>
      <Image src={image} />
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  );
};

export { Benefit };
