import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  margin-left: 5px;
  margin-right: 5px;
`;

export const Title = styled.div`
  align-self: center;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 33px;
  color: #073d59;
`;

export const GoalsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const GoalItem = styled.button`
  display: flex;
  width: 100%;
  height: 75px;
  margin-top: 30px;
  border-radius: 10px;
  align-items: center;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
  cursor: pointer;
  outline: none;
`;

export const GoalItemText = styled.div`
  font-family: 'Mulish';
  font-size: 15px;
  width: 60%;
`;

export const BlankRound = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: white;
  align-items: center;
  justify-content: center;
`;

export const SelectedRound = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #073d59;
`;

export const Icon = styled.img`
  width: 45px;
  height: 45px;
`;

export const ContinueButton = styled.button`
  width: 100%;
  height: 56px;
  margin-top: 30px;
  border-radius: 10px;
  background-color: #073d59;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex-direction: row;
  cursor: pointer;
`;

export const ContinueButtonText = styled.div`
  font-size: 18px;
  font-family: 'Mulish';
  color: white;
`;
