import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../App';
import { Form, SubmitInput, Error, Success } from './styles';

const TestDiversifiedNotifications: React.FC = () => {
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  const [error, setError] = useState<string>('');
  const [success] = useState<string>('');

  const [date, setDate] = useState('');

  const handleChange = (event: any) => {
    setDate(event.target.value);
  };
  const handleClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setError('');
    if (user != null) {
      try {
        const selectedDate = new Date(date);
        await user.callFunction('websiteInternalFunctions/notificationTrigger', {
          timeMock: selectedDate
        });
      } catch (e: any) {
        setError(e);
      }
    } else navigate('/admin/login');
  };

  return (
    <Form onSubmit={handleClick}>
      <h2>To use this feature make sure that:</h2>
      <ul>
        <li>
          You just created a fresh new user that has admin privileges and signed to this portal with
          that user.
        </li>
        <li>You have signed in with this user on the internal app on your phone.</li>
        <li>Started a practical guide.</li>
      </ul>
      <input type="date" value={date} onChange={handleChange} />
      <p>Selected Date: {date}</p>
      <SubmitInput type="submit" value="Trigger Notifications" />
      {error.length > 0 && <Error>{error}</Error>}
      {success.length > 0 && <Success>{success}</Success>}
    </Form>
  );
};

export { TestDiversifiedNotifications };
