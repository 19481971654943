import React, { useEffect, useState } from 'react';
import { PageHOC } from '../../components';
import { ConfirmationContainer, Title, Text } from './styles';
import { App as RealmApp } from 'realm-web';
import { useSearchParams } from 'react-router-dom';
import { APP_IDS } from '../../utils/constants';
import { TrackingService } from '../../utils/trackingService';

interface ConfirmUserParams {
  appId: string;
  token: string;
  tokenId: string;
}

const appIdShortcuts: { [key: string]: string } = {
  p: APP_IDS.PROD_APP_ID,
  s: APP_IDS.STAGING_APP_ID,
  i: APP_IDS.INTERNAL_APP_ID
};

const Confirmation: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [confirmationText, setConfirmationText] = useState('');
  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    async function confirmUser({ appId, tokenId, token }: ConfirmUserParams) {
      if (appIdShortcuts[appId] != null) {
        appId = appIdShortcuts[appId];
      }
      try {
        const realm = RealmApp.getApp(appId);
        await realm.emailPasswordAuth.confirmUser({ token, tokenId });
        setConfirmationText('Confirmation successful. Please log in to the Niyyah App.');
        TrackingService.track('confirmation_page', { status: 'success' });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        setConfirmationText(
          `Unable to confirm user, error: ${
            e.error ?? ''
          }. Please try to re-login in the Niyyah App`
        );
        TrackingService.track('confirmation_page', { status: 'fail', error: e.error ?? '' });
      }
    }
    const token = searchParams.get('token');
    const tokenId = searchParams.get('tokenId');
    const appId = searchParams.get('appId');

    if (token != null && tokenId != null && appId != null) {
      setConfirmationText('Confirming user account. Please wait...');
      confirmUser({ token, tokenId, appId });
    } else {
      setConfirmationText(
        'Malformed URL. Please make sure you have opened the correct confirmation link.'
      );
      TrackingService.track('confirmation_page', { status: 'malformed_url' });
    }
  }, [searchParams]);

  return (
    <PageHOC showAction={false}>
      <ConfirmationContainer>
        <Title>Niyyah - Account Confirmation</Title>
        <Text>{confirmationText}</Text>
      </ConfirmationContainer>
    </PageHOC>
  );
};

export { Confirmation };
