import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../App';
import { Form, SubmitInput, Error, Success } from './styles';

// JsonTable component
interface JsonTableProps {
  data: Array<NotificationListObject>;
}

interface NotificationListObject {
  date: string;
  hour: number;
  text: string;
  _id: any;
}

const JsonTable = ({ data }: JsonTableProps) => {
  if (!data) return null;
  return (
    <div style={{ overflowY: 'auto', height: '300px' }}>
      <table style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid black' }}>ID</th>
            <th style={{ border: '1px solid black' }}>Name</th>
            <th style={{ border: '1px solid black' }}>Age</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td style={{ border: '1px solid black' }}>{item._id.toString()}</td>
              <td style={{ border: '1px solid black' }}>{item.date}</td>
              <td style={{ border: '1px solid black' }}>{item.hour}</td>
              <td style={{ border: '1px solid black' }}>{item.text}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// EditNotifications component
const EditNotifications: React.FC = () => {
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [jsonData, setJsonData] = useState<any[]>([]);

  const handleListMassNotifications = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setError('');

    if (user != null) {
      user.functions
        .listMassNotifications({})
        .then((res) => {
          //console.log(res);
          //setSuccess(JSON.stringify(res));
          setJsonData(res);
        })
        .catch((e: any) => {
          setError(e?.error);
        });
    } else {
      navigate('/admin/login');
    }
  };

  return (
    <Form onSubmit={handleListMassNotifications}>
      <SubmitInput type="submit" value="List notifications" />
      {error.length > 0 && <Error>{error}</Error>}
      {success.length > 0 && <Success>{success}</Success>}
      {jsonData.length > 0 && <JsonTable data={jsonData} />}
    </Form>
  );
};

export { EditNotifications };
