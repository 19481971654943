import styled from 'styled-components';
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  width: 100%;
  height: 80px;
  background-color: #121212;
  z-index: 1000;
`;

export const Logo = styled.img`
  margin-left: 5%;
  max-height: 100%;
  height: 80%;
  cursor: pointer;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 5%;
  min-width: max(15%, 100px);
  max-height: 100%;
  height: 50%;
`;

export const MainButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1.5% 0 1.5%;
  width: 60%;
  min-height: 40px;
  border-radius: 8px;
  cursor: pointer;
  background-color: white;
  border: 1px solid #ffffff;
`;

export const ButtonText = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: max(1vw, 10px);
  white-space: nowrap;
  color: #115f6a;
`;
