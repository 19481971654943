import { ResetPassword } from '../pages/ResetPassword';
import { useContext } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import { AppContext } from '../App';
import {
  Home,
  Confirmation,
  Payment,
  IntroGuide,
  Privacy,
  Donation,
  SignIn,
  AdminPortal
} from '../pages';
import { Leaderboard } from '../pages/Leaderboard';

export default function Router() {
  const { user } = useContext(AppContext);
  const routes = useRoutes([
    {
      path: '/',
      element: <Home />
    },
    {
      path: '/confirm',
      element: <Confirmation />
    },
    {
      path: '/resetpassword',
      element: <ResetPassword />
    },
    {
      path: '/donation/donate',
      element: <Payment />
    },
    {
      path: '/guide/intro-guide',
      element: <IntroGuide />
    },
    {
      path: '/privacy',
      element: <Privacy />
    },
    {
      path: '/gift',
      element: <Donation />
    },
    { path: 'admin/login', element: user ? <Navigate to={'/admin/portal'} replace /> : <SignIn /> },
    {
      path: 'admin/portal',
      element: user ? <AdminPortal /> : <Navigate to={'/admin/login'} replace />
    },
    {
      path: '/groups',
      element: <Leaderboard />
    }
  ]);
  return routes;
}
