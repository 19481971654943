import React, { useState, useEffect } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { useWindowWidthAndHeight } from '../../utils/windowSizeHook';
import { Testimonial } from '../../components';
import {
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8
} from '../../assets/testimonials';
import { Container, Title, TestimonialsContainer } from './styles';
import 'react-alice-carousel/lib/alice-carousel.css';

interface ITestimonialData {
  name: string;
  title: string;
  text: string;
  avatar: any;
}

const responsive = {
  0: {
    items: 1
  },
  1000: {
    items: 2,
    itemsFit: 'fill'
  },
  1200: {
    items: 3,
    itemsFit: 'fill'
  },
  1450: {
    items: 4,
    itemsFit: 'fill'
  }
};

const renderItem = (item: ITestimonialData, key: any, isSelected: boolean) => (
  <div key={key}>
    <Testimonial
      name={item.name}
      title={item.title}
      text={item.text}
      avatar={item.avatar}
      isSelected={isSelected}
    />
  </div>
);

const data = [
  {
    name: 'Omar',
    title: 'United Kingdom',
    text: 'Guider is great. I have yet to find a more useful, convenient Islamic education app. Guider tops the list for me, and I feel truly blessed to be amongst those who have tested it in its early stages.',
    avatar: avatar1
  },
  {
    name: 'Fatima',
    title: 'Spain',
    text: 'I love this app! With just a few minutes a day you feel the difference and how it really has an effect on you, very original, practical and effective Alhamdulillah!',
    avatar: avatar2
  },
  {
    name: 'Tom',
    title: 'Kuwait',
    text: 'My favourite thing about the Guider app is the quizzes. It is very easy to read something without fully taking it in. These quizzes help make sure you remember what you have learned!',
    avatar: avatar3
  },
  {
    name: 'Kaoutar',
    title: 'Belgium',
    text: 'What an amazing app, perfect for those with busy lives especially with the daily notification we always stay reminded of the deen! Beautiful topics that are needed nowadays!',
    avatar: avatar4
  },
  {
    name: 'Demi',
    title: 'United Kingdom',
    text: "I’ve loved using Guider so far! Because my daily reminders are always bite-sized, it's something that works around my schedule well, and the personalisation helps me improve my Deen in a tangible way.",
    avatar: avatar5
  },
  {
    name: 'Iqra',
    title: 'United Kingdom',
    text: 'Absolutely LOVE this app. A unique and interactive approach with concise yet informative content that is so beneficial! May Allah reward all those involved and allow it to benefit many Muslims!',
    avatar: avatar6
  },
  {
    name: 'Sharim',
    title: 'United Kingdom',
    text: 'Guider is the perfect app for busy Muslims to keep in touch with their faith. The bitesize lessons covering a wide range of topics are highly accessible and easy to make part of my daily routine!',
    avatar: avatar7
  },
  {
    name: 'Farzana',
    title: 'USA',
    text: 'Guider is the Duolingo of the Muslim community. It motivates you to stay grounded with religion through bite-sized content and a supportive community!',
    avatar: avatar8
  }
];

const TestimonialsScreen: React.FC = () => {
  const [selectedTestimonial, setSelectedTestimonial] = useState<number>(0);
  const [testimonials, setTestimonials] = useState<any>([]);

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [width, height] = useWindowWidthAndHeight();
  const isMobile = width <= 1000;

  useEffect(() => {
    setTestimonials(
      data.map((item, index) => renderItem(item, index, index === selectedTestimonial))
    );
  }, [selectedTestimonial]);

  return (
    <Container isMobile={isMobile}>
      <Title>Don’t take our word for it</Title>
      <TestimonialsContainer>
        <AliceCarousel
          mouseTracking
          items={testimonials}
          disableButtonsControls
          responsive={responsive}
          activeIndex={selectedTestimonial}
          autoPlay={!isMobile}
          autoPlayInterval={isMobile ? 0 : 3000}
          infinite
          onSlideChanged={(e) => {
            setSelectedTestimonial(e.item % data.length);
          }}
        />
      </TestimonialsContainer>
    </Container>
  );
};

export { TestimonialsScreen };
