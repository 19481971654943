import styled from 'styled-components';

export const HeroContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${(props) => (props.isMobile ? '0 10%' : '0 76px')};
  background-color: #fdfbf5;
`;

export const UpperContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 70px;
`;

export const MobileContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

export const LeftContainer = styled.div<{ isMobile: boolean }>`
  flex: 0.6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => (props.isMobile ? 'center' : undefined)};
  margin-right: ${(props) => (props.isMobile ? '0' : '10%')};
  margin-bottom: 20px;
`;

export const Title = styled.div<{ isMobile: boolean }>`
  margin-bottom: 24px;
  margin-top: ${(props) => (props.isMobile ? '24px' : 0)};
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: ${(props) => (props.isMobile ? '30px' : '60px')};
  line-height: ${(props) => (props.isMobile ? '40px' : '75px')};
  color: #1e293b;
`;

export const Description = styled.div<{ isMobile: boolean }>`
  margin-bottom: 40px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => (props.isMobile ? '17px' : '22px')};
  line-height: 30px;
  color: #667b99;
`;

export const Button = styled.a<{ isMobile: boolean }>`
  align-items: center;
  width: 40%;
  padding: 12px 24px;
  display: flex;
  justify-content: center;
  background: #073d59;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #073d59;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => (props.isMobile ? '15px' : '18px')};
  line-height: ${(props) => (props.isMobile ? '18px' : '22px')};
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
`;

export const ImageContainer = styled.div<{ isMobile: boolean }>`
  flex: 0.4;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const Image = styled.img`
  display: inline-block;
  height: auto;
  max-width: 100%;
  min-width: min(600px, 100%);
`;

export const BottomContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  margin-bottom: 26px;
`;
