import styled from 'styled-components';

export const Container = styled.div`
  background: #fffff;
`;

export const InternalContainer = styled.div<{ isMobile: boolean }>`
  height: 100%;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  margin-top: 100px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  color: #1e293b;
`;

export const Description = styled.div`
  margin-top: 24px;
  margin-bottom: 100px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #434344;
`;

export const BenefitsContainer = styled.div`
  width: 90%;
`;

export const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  @media (min-width: 1800px) {
    margin: 0 10%;
  }
  @media (min-width: 2600px) {
    margin: 0 20%;
  }
  @media (min-width: 4000px) {
    margin: 0 30%;
  }
`;

export const MobileFirstRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > * {
    margin-bottom: 50px;
  }
`;
