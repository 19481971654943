import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Tiktok from '../../assets/svg/Tiktok';
import Instagram from '../../assets/svg/Instagram';
import LinkedIn from '../../assets/svg/LinkedIn';

export const Container = styled.div<{ isMobile: boolean }>`
  background: #121212;
  height: ${(props) => (props.isMobile ? '450px' : '250px')};
  width: 100%;
`;

export const Divider = styled.div`
  border: 1px solid #121212;
`;

export const UpperFooterContainer = styled.div`
  height: 70%;
  margin: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UpperFooterMobileContainer = styled.div`
  height: 80%;
  margin: 0 5%;
  display: flex;
  flex-direction: column;
`;

export const UpperFooterLogo = styled.img`
  max-width: 20%;
  max-height: 50%;
  cursor: pointer;
`;

export const MobileUpperFooterLogoContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavigationContatiner = styled.div`
  width: 25%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const MobileNavigationContatiner = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NavigationItem = styled(Link)<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  margin: ${(props) => (props.isMobile ? '10px 0' : '10px')};
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => (props.isMobile ? '14px' : '18px')};
  line-height: 25px;
  text-decoration: none;
  color: #ffffff;
`;

export const ExternalNavigationItem = styled('a')<{ isMobile: boolean }>`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => (props.isMobile ? '14px' : '18px')};
  line-height: 25px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #ffffff;
`;

export const ScrollTopWrapper = styled.button`
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
`;

export const ScrollTop = styled.img`
  max-width: 70%;
  aspect-ratio: 1 / 1;
`;

export const ContactUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70%;
`;

export const MobileContactUsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContactUsText = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 39px;
  color: #cbd5e1;
`;

export const ContactEmailText = styled.div`
  cursor: pointer;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #cbd5e1;
`;

export const LowerFooterContainer = styled.div`
  height: 20%;
  margin: 0 5%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CopyRightText = styled.div<{ isMobile: boolean }>`
  max-width: 70%;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => (props.isMobile ? '12px' : '16px')};
  line-height: 22px;
  color: #ffffff;
`;

export const SocialMediaContatiner = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
`;

export const TiktokIcon = styled(Tiktok)``;

export const InstagramIcon = styled(Instagram)``;

export const LinkedInIcon = styled(LinkedIn)``;

export const Logo = styled.img`
  margin-left: 5%;
  max-height: 100%;
  height: 60%;
`;
