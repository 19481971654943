import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: #073d59;
`;

export const CustomLink = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  margin: 12px;
  gap: 12px;
  width: 250px;
  height: 40px;
  background: #0074d4;
  border-radius: 8px;
  color: white;
  text-decoration: none;
`;
