import styled from 'styled-components';
import { Link } from 'react-router-dom';
import backgroundImage1 from '../../assets/demo/demoBackground1.png';
import backgroundImage2 from '../../assets/demo/demoBackground2.png';
import backgroundImage3 from '../../assets/demo/demoBackground3.png';
import iphone from '../../assets/demo/iphone.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${backgroundImage2}), url(${backgroundImage1}), url(${backgroundImage3});
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: left, right, center;
  background-color: #fdfbf5;
  height: 90vh;
  min-height: 800px;
`;

export const PhoneContainer = styled.div`
  width: 340px;
  height: 688px;
  background-image: url(${iphone});
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

export const ComponentContainer = styled.div`
  background-color: #fdfbf5;
  margin: 80px 0 0 25px;
  width: 85%;
  height: 82%;
`;

export const Footer = styled.div`
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FooterText = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #073d59;
`;

export const ClickHereLink = styled(Link)`
  text-decoration: none;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #0bceb2;
`;
