import styled from 'styled-components';

export const Container = styled.div`
  background: #fffff;
`;

export const InternalContainer = styled.div<{ isMobile: boolean }>`
  height: 100%;
  margin: ${(props) => (props.isMobile ? '0 10px' : '0 76px')};
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fdfbf5;
  border-bottom-left-radius: 250px;
  border-bottom-right-radius: 250px;
`;

export const Title = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 62px;
  text-align: center;
  color: #073d59;
`;

export const BenefitsContainer = styled.div`
  width: 90%;
`;

export const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  @media (min-width: 1800px) {
    margin: 0 10%;
  }
  @media (min-width: 2600px) {
    margin: 0 20%;
  }
  @media (min-width: 4000px) {
    margin: 0 30%;
  }
  > * {
    margin-bottom: 50px;
  }
`;

export const MobileFirstRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > * {
    margin-bottom: 50px;
  }
`;

export const SecondRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  > * {
    margin-bottom: 50px;
  }
`;
