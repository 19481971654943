import React, { useEffect } from 'react';
import { Header, Footer } from '../../components';
import { Container, Content } from './styles';

interface IProps {
  showAction: boolean;
  children: JSX.Element;
  onLearnMore?: () => void;
}

const PageHOC: React.FC<IProps> = ({ showAction, children, onLearnMore }) => {
  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <Container>
      <Header showAction={showAction} onLearnMore={onLearnMore} />
      <Content>{children}</Content>
      <Footer />
    </Container>
  );
};

export { PageHOC };
