import styled from 'styled-components';

export const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 67vh;
  align-items: center;
  background-color: #231f20;
`;

export const Title = styled.h1`
  padding: 0 10px;
  color: #fff;
`;

export const Text = styled.h3`
  padding: 0 10px;
  color: #fff;
`;

export const BoldText = styled.span`
  padding: 0 10px;
  color: #fff;
  font-weight: bold;
`;

export const Email = styled.span`
  cursor: pointer;
  color: blue;
`;
