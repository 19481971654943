import React from 'react';
import { Scholarship } from '../../components';
import { useWindowWidthAndHeight } from '../../utils/windowSizeHook';
import { scholar1, scholar2, scholar3 } from '../../assets/donation';
import {
  Container,
  InternalContainer,
  Title,
  Description,
  BenefitsContainer,
  FirstRow,
  MobileFirstRow
} from './styles';

const ScholarshipScreen: React.FC = () => {
  const dimensions = useWindowWidthAndHeight();
  const width = dimensions[0];
  const isMobile = width <= 1000;

  const Row = isMobile ? MobileFirstRow : FirstRow;
  return (
    <Container>
      <InternalContainer isMobile={isMobile}>
        <Title>Why gift Guider?</Title>
        <Description>Give your team a boost for Eid and beyond.</Description>
        <BenefitsContainer>
          <Row>
            <Scholarship
              image={scholar1}
              title={'Meaningful'}
              description={'Enable your team to build and maintain productive habits'}
            />
            <Scholarship
              image={scholar3}
              title={'Accessible'}
              description={'Easily gift access for team members based anywhere in the world'}
            />
            <Scholarship
              image={scholar2}
              title={'Personalised'}
              description={
                'Give flexibility for each person to choose the goals and topics they care about'
              }
            />
          </Row>
        </BenefitsContainer>
      </InternalContainer>
    </Container>
  );
};

export { ScholarshipScreen };
