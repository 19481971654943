import React, { useEffect } from 'react';
import { PageHOC } from '../../components';
import { DonationHeroScreen, ScholarshipScreen, FAQScreen } from '../../screens';
import { Container } from './styles';

const Donation: React.FC = () => {
  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <PageHOC showAction={false}>
      <Container>
        <DonationHeroScreen />
        <ScholarshipScreen />
        <FAQScreen />
      </Container>
    </PageHOC>
  );
};

export { Donation };
