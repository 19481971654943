import styled from 'styled-components';
import { Collapse } from 'react-collapse';

export const Container = styled.div`
  width: 100%;
`;

export const Header = styled.button`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 19px 37px;
  border-radius: 5px 5px 0px 0px;
  border: none;
  cursor: pointer;
`;

export const ButtonText = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
`;

export const Arrow = styled.img``;

export const CollapseElement = styled(Collapse)``;

export const CollapseText = styled.div`
  margin: 20px 40px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  color: #6b7280;
`;

export const CollapseTextContainer = styled.div<{ open: boolean }>`
  border: ${(props) => (props.open ? '1px solid #e5e7eb' : 'none')};
`;
