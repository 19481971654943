import React from 'react';
import { useWindowWidthAndHeight } from '../../utils/windowSizeHook';
import { hero1, hero2 } from '../../assets/donation';
import {
  HeroContainer,
  UpperContainer,
  MobileContainer,
  LeftContainer,
  Title,
  Description,
  Button,
  ImageContainer,
  Image,
  BottomContainer
} from './styles';

const DonationHeroScreen: React.FC = () => {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [width, height] = useWindowWidthAndHeight();
  const isMobile = width <= 1000;

  const MainUpperContainer = isMobile ? MobileContainer : UpperContainer;

  return (
    <HeroContainer isMobile={isMobile}>
      <MainUpperContainer>
        <LeftContainer isMobile={isMobile}>
          <Title isMobile={isMobile}>Give your team an Eid gift they won’t forget</Title>
          <Description isMobile={isMobile}>
            {
              'Give the perfect gift this Eid with Guider - the daily guide for busy Muslims. Help your team to beat their post-Ramadan blues and build new habits all-year-round.'
            }
          </Description>
          <Button
            isMobile={isMobile}
            target={'_blank'}
            href={'https://buy.stripe.com/5kAbKj8ZbfI1g6I6op'}>
            Gift Guider
          </Button>
        </LeftContainer>
        <ImageContainer isMobile={isMobile}>
          <Image src={hero1} />
        </ImageContainer>
      </MainUpperContainer>
      <BottomContainer>
        <Image src={hero2} />
      </BottomContainer>
    </HeroContainer>
  );
};

export { DonationHeroScreen };
