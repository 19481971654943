import mixpanel from 'mixpanel-browser';

mixpanel.init('ee140fec7ebbf75e8cf0742d74935062');

const isProd = process.env.REACT_APP_ENVIRONMENT === 'production';

const actions = {
  identify: (id: any) => {
    if (isProd) mixpanel.identify(id);
  },
  alias: (id: any) => {
    if (isProd) mixpanel.alias(id);
  },
  track: (name: string, props?: any) => {
    if (isProd) mixpanel.track(name, props);
  },
  people: {
    set: (props: any) => {
      if (isProd) mixpanel.people.set(props);
    }
  }
};

export const TrackingService = actions;
