import styled, { css } from 'styled-components';

export const Container = styled.div<{ isMobile: boolean }>`
  padding: ${(props) => (props.isMobile ? '100px 10px' : '100px 76px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fffff;
`;

export const Title = styled.div`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 62px;
  text-align: center;
  color: #073d59;
`;

export const TestimonialsContainer = styled.div`
  width: 100%;
  padding-top: 100px;

  ${css`
    li.alice-carousel__stage-item :not(.__cloned) {
      width: auto !important;
      margin-right: 10px;
    }
  `}
`;
