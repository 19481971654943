import React, { useState } from 'react';
import { TrackingService } from '../../utils/trackingService';
import { GoalsIds } from '../../utils/constants';
import PrayerImg from '../../assets/demo/prayer.png';
import QuranImg from '../../assets/demo/quran.png';
import ConnectionImg from '../../assets/demo/connection.png';
// import HabitsImg from '../../assets/demo/habits.png';
import GoalImg from '../../assets/demo/goal.png';
import {
  Container,
  Title,
  GoalsContainer,
  GoalItem,
  GoalItemText,
  BlankRound,
  SelectedRound,
  Icon,
  ContinueButton,
  ContinueButtonText
} from './styles';

interface IGoalItem {
  text: any;
  selected: boolean;
  image: JSX.Element;
  id: string;
}

const initialGoalOptions: IGoalItem[] = [
  {
    text: (
      <>
        Improve my <span style={{ fontWeight: 'bold' }}>daily prayers (salah)</span>
      </>
    ),
    selected: true,
    image: <Icon src={PrayerImg} />,
    id: GoalsIds.PRAYERS
  },
  {
    text: (
      <>
        Live my life by the <span style={{ fontWeight: 'bold' }}>Qur&apos;an</span>
      </>
    ),
    selected: false,
    image: <Icon src={QuranImg} />,
    id: GoalsIds.QURAN
  },
  {
    text: (
      <>
        Build a better <span style={{ fontWeight: 'bold' }}>connection with Allah</span>
      </>
    ),
    selected: false,
    image: <Icon src={ConnectionImg} />,
    id: GoalsIds.CONNECTION
  },
  // {
  //   text: (
  //     <>
  //       Learn habits from <span style={{ fontWeight: 'bold' }}>the Prophet (pbuh)</span>
  //     </>
  //   ),
  //   selected: false,
  //   image: <Icon src={HabitsImg} />,
  //   id: GoalsIds.HABITS
  // },
  {
    text: <>I don’t know where to start</>,
    selected: false,
    image: <Icon src={GoalImg} />,
    id: GoalsIds.GOAL
  }
];

interface Props {
  onContinue: (selectedGoalId: string) => void;
}

const DemoFirstScreen: React.FC<Props> = ({ onContinue }) => {
  const [goalOptions, setGoalOptions] = useState<IGoalItem[]>(initialGoalOptions);

  const renderItem: React.FC<IGoalItem> = (item: IGoalItem) => {
    const color = item.selected ? '#daecd6' : 'transparent';
    return (
      <GoalItem
        onClick={() => {
          const updatedGoalOptions = goalOptions.map((option: IGoalItem) => {
            return { ...option, selected: option.id === item.id };
          });
          TrackingService.track(`goal_id_${item.id}_clicked`);
          setGoalOptions(updatedGoalOptions);
        }}
        style={{
          backgroundColor: color,
          border: item.selected ? '1px solid transparent' : '1px solid #acbdc2'
        }}>
        <>
          {item?.image}
          <GoalItemText
            style={{
              color: item.selected ? '#073D59' : 'black'
            }}>
            {item.text}
          </GoalItemText>
          <BlankRound
            style={{
              border: item.selected ? '1px solid white' : '1px solid black'
            }}>
            {item.selected && <SelectedRound />}
          </BlankRound>
        </>
      </GoalItem>
    );
  };

  return (
    <Container>
      <Title>What is your #1 Goal?</Title>
      <GoalsContainer>{goalOptions.map((item) => renderItem(item))}</GoalsContainer>
      <ContinueButton
        onClick={() => {
          TrackingService.track(`demo_first_screen_continue_clicked`);
          const selectedGoal = goalOptions.find((option: IGoalItem) => option.selected);
          selectedGoal != null && onContinue(selectedGoal.id);
        }}>
        <ContinueButtonText>Continue</ContinueButtonText>
      </ContinueButton>
    </Container>
  );
};

export { DemoFirstScreen };
