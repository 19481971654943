import React from 'react';
import { useWindowWidthAndHeight } from '../../utils/windowSizeHook';
import { TrackingService } from '../../utils/trackingService';
import heroImg from '../../assets/heroImage.png';
import androidDownloadImg from '../../assets/android.png';
import iosDownloadImg from '../../assets/ios.png';
import site1 from '../../assets/site1.png';
import site2 from '../../assets/site2.png';
import site3 from '../../assets/site3.png';
import {
  Container,
  MobileContainer,
  LeftContainer,
  LeftUpperContainer,
  LeftLowerContainer,
  FeaturedOnIcons,
  FeaturedOnIconContainer,
  FeaturedOnIcon,
  FeaturedOnText,
  Title,
  Description,
  DownloadLinksContainer,
  DownloadImage,
  ImageContainer,
  Image
} from './styles';

const HeroScreen: React.FC = () => {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [width, height] = useWindowWidthAndHeight();
  const isMobile = width <= 1000;

  const HeroContainer = isMobile ? MobileContainer : Container;

  return (
    <HeroContainer>
      <LeftContainer isMobile={isMobile}>
        <LeftUpperContainer isMobile={isMobile}>
          <Title isMobile={isMobile}>Draw closer to Allah daily</Title>
          <Description isMobile={isMobile}>
            Make meaningful progress towards your most important goals in less than 5 minutes a day.
          </Description>
          <DownloadLinksContainer>
            <a
              href={
                'https://play.google.com/store/apps/details?id=com.guider.guidermobile&hl=en&pli=1'
              }
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                TrackingService.track('hero_android_download_button_clicked');
              }}>
              <DownloadImage src={androidDownloadImg} />
            </a>
            <a
              href={'https://apps.apple.com/gb/app/guider-the-app-for-muslims/id6446500974'}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                TrackingService.track('hero_ios_download_button_clicked');
              }}>
              <DownloadImage src={iosDownloadImg} />
            </a>
          </DownloadLinksContainer>
        </LeftUpperContainer>
        <LeftLowerContainer isMobile={isMobile}>
          <FeaturedOnText>As featured on:</FeaturedOnText>
          <FeaturedOnIcons isMobile={isMobile}>
            <FeaturedOnIconContainer
              isMobile={isMobile}
              href={
                'https://markets.businessinsider.com/news/stocks/guider-announces-the-upcoming-launch-of-their-categorydefining-educational-app-for-muslims-1032058576'
              }
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                TrackingService.track('hero_insider_icon_clicked');
              }}>
              <FeaturedOnIcon
                src={site1}
                style={isMobile ? { width: '100px' } : { maxWidth: 'max(100%, 80px)' }}
              />
            </FeaturedOnIconContainer>
            <FeaturedOnIconContainer
              isMobile={isMobile}
              href={
                'https://www.islamchannel.tv/blog-posts/as-we-finish-ramadan-how-can-we-continue-our-level-of-worship-and-ibadah'
              }
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                TrackingService.track('hero_islam_chn_icon_clicked');
              }}
              style={{ justifyContent: 'end', marginRight: '20px' }}>
              <FeaturedOnIcon
                src={site2}
                style={
                  isMobile ? { width: '50px' } : { maxWidth: 'max(100%, 80px)', maxHeight: '50px' }
                }
              />
            </FeaturedOnIconContainer>
            <FeaturedOnIconContainer
              isMobile={isMobile}
              href={'https://www.islamicfinanceguru.com/articles'}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                TrackingService.track('hero_ifg_icon_clicked');
              }}>
              <FeaturedOnIcon
                src={site3}
                style={
                  isMobile ? { width: '50px' } : { maxWidth: 'max(20%, 80px)', maxHeight: '50px' }
                }
              />
            </FeaturedOnIconContainer>
          </FeaturedOnIcons>
        </LeftLowerContainer>
      </LeftContainer>
      <ImageContainer isMobile={isMobile}>
        <Image src={heroImg} />
      </ImageContainer>
    </HeroContainer>
  );
};

export { HeroScreen };
