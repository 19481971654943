import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../App';
import { Form, SubmitInput, Error, Success, Input } from './styles';

// JsonTable component
interface JsonTableProps {
  data: { [key: string]: any };
}

const JsonTable: React.FC<JsonTableProps> = ({ data }) => {
  // Convert the object to an array of objects with key and value properties
  const dataArray = Object.entries(data).map(([key, value]) => ({
    key,
    value: value
  }));

  return (
    <div style={{ overflowY: 'auto', height: '300px' }}>
      <table style={{ width: '100%', border: '1px solid black', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid black', textAlign: 'left', padding: '8px' }}>Key</th>
            <th style={{ border: '1px solid black', textAlign: 'left', padding: '8px' }}>Value</th>
          </tr>
        </thead>
        <tbody>
          {dataArray.map((item, index) => (
            <tr key={index}>
              <td style={{ border: '1px solid black', padding: '8px' }}>{item.key}</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>
                {typeof item.value === 'object'
                  ? JSON.stringify(item.value)
                  : item.value.toString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const getAppIds = () => {
  return {
    STAGING_APP_ID: 'guider-staging-kuuyy',
    INTERNAL_APP_ID: 'guider-internal-difnd',
    PROD_APP_ID: 'guider-atlas-production-qoaqw'
  };
};

// MobileConfigViewer component
const MobileConfigViewer: React.FC = () => {
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [jsonData, setJsonData] = useState<any>(null);

  const handleFetchMobileConfig = (e: React.SyntheticEvent) => {
    setJsonData(null);
    const target = e.target as typeof e.target & {
      version: { value: string };
    };
    e.preventDefault();
    setError('');
    console.log(target.version.value);
    if (user != null) {
      user.functions
        .fetchMobileConfig({ version: target.version.value, appId: 'guider-internal-difnd' })
        .then((res) => {
          console.log(res);
          //setSuccess(JSON.stringify(res));
          setJsonData(res);
        })
        .catch((e: any) => {
          console.log(e);
          setError(e?.error);
        });
    } else {
      navigate('/admin/login');
    }
  };

  const appIds = getAppIds();

  return (
    <Form onSubmit={handleFetchMobileConfig}>
      <Input placeholder="Version" name="version" />
      <select name="appId">
        {Object.entries(appIds).map(([key, value]) => (
          <option key={key} value={value}>
            {key}
          </option>
        ))}
      </select>
      <SubmitInput type="submit" value="List MobileConfig" />
      {error.length > 0 && <Error>{error}</Error>}
      {success.length > 0 && <Success>{success}</Success>}
      {jsonData != null && <JsonTable data={jsonData} />}
    </Form>
  );
};

export { MobileConfigViewer };
