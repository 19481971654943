import styled, { css, keyframes } from 'styled-components';
import background from '../../assets/followBackground.png';

export const Container = styled.div`
  min-height: 700px;
  display: flex;
  flex: 1;
  flex-direction: row;
  background-image: url(${background});
  background-color: #073d59;
`;

export const MobileContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  background-image: url(${background});
  background-color: #073d59;
`;

export const LeftContainer = styled.div`
  flex: 0.7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div<{ isMobile: boolean }>`
  margin: 0 10px;
  margin-bottom: 80px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 700;
  font-size: ${(props) => (props.isMobile ? '25px' : '48px')};
  line-height: ${(props) => (props.isMobile ? '40px' : '66px')};
  text-align: center;
  color: #ffffff;
`;

export const InputContainer = styled.div`
  margin-top: 50px;
  width: 80%;
  min-height: 55px;
  display: flex;
  justify-content: center;
`;

const glow = keyframes`
 50% {
    background: lightgreen;
    box-shadow: 0 0 10px lightgreen;
  }
`;

export const Input = styled.input<{ isMobile: boolean }>`
  margin: 0;
  width: ${(props) => (props.isMobile ? '50%' : '35%')};
  padding-left: 20px;
  border: 0.927103px solid #ffffff;
  border-radius: 11.1252px 0px 0px 11.1252px;
  background: transparent;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #ffffff;
  }

  ${(props) =>
    props.className === 'highlight-overlay' &&
    css`
      animation: ${glow} 700ms 2;
    `};
`;

export const Button = styled.button<{ isMobile: boolean }>`
  margin: 0;
  width: ${(props) => (props.isMobile ? '40%' : '25%')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 0px 11.1252px 11.1252px 0px;
  border: 0.927103px solid #ffffff;
  cursor: pointer;
`;

export const ButtonText = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: #115f6a;
`;

export const MessageContainer = styled.div<{ isSuccess: boolean }>`
  margin-top: 10px;
  font-family: 'Mulish';
  color: ${(props) => (props.isSuccess ? 'white' : 'red')};
`;

export const RightContainer = styled.div`
  flex: 0.3;
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const Image = styled.img`
  cursor: pointer;
`;
