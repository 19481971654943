import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../App';
import { Form, Input, SubmitInput, Error, Success } from './styles';

const CodeRedemptions: React.FC = () => {
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');

  const handleYesClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setError('');
    const target = e.target as typeof e.target & {
      promocodeName: { value: string };
    };

    const promocodeValue = target.promocodeName.value;
    if (!promocodeValue) {
      alert('Invalid input for promocode to check');
      return;
    }
    if (user != null) {
      try {
        const count = await user.callFunction(
          'websiteInternalFunctions/getPromocodeRedemptionCount',
          { promocodeName: promocodeValue }
        );
        setSuccess(`Code ${promocodeValue} redeemed ${count} times`);
      } catch (e: any) {
        setError(e);
      }
    } else navigate('/admin/login');
  };

  return (
    <Form onSubmit={handleYesClick}>
      <Input placeholder="Promo Code to check" name="promocodename" />
      <SubmitInput type="submit" value="Get redemptions" />
      {error.length > 0 && <Error>{error}</Error>}
      {success.length > 0 && <Success>{success}</Success>}
    </Form>
  );
};

export { CodeRedemptions };
