import styled from 'styled-components';

export const Container = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: ${(props) => (props.isMobile ? '0px 50px' : '0px 120px')};
`;

export const UpperContainer = styled.div<{ isMobile: boolean }>`
  padding: ${(props) => (props.isMobile ? '100px 50px' : '100px 150px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 2s ease;
`;

export const Title = styled.div`
  margin-bottom: 32px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 66px;
  text-align: center;
  color: #1e293b;
`;

export const SubTitle = styled.div`
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
  color: #434344;
`;

export const FAQContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 12px;
`;

export const LowerContainer = styled.div`
  margin-bottom: 100px;
`;

export const Space = styled.div`
  height: 16px;
`;
