import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  margin-left: 5px;
  margin-right: 5px;
`;

export const UpperContainer = styled.div`
  height: 100%;
  margin: 10px 10px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Image = styled.img`
  height: 280px;
  width: 260px;
  margin-bottom: 10px;
`;

export const Congrats = styled.div`
  margin-bottom: 10px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 31px;
  text-align: center;
  background: linear-gradient(90.31deg, #209fb1 0.27%, #56becd 99.87%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

export const Title = styled.div`
  margin-bottom: 40px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #073d59;
`;

export const ContinueButton = styled.button`
  width: 100%;
  min-height: 56px;
  border-radius: 10px;
  background-color: #073d59;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex-direction: row;
  cursor: pointer;
`;

export const ContinueButtonText = styled.div`
  font-size: 18px;
  font-family: 'Mulish';
  color: white;
`;
