import React from 'react';
import { Benefit } from '../../components';
import { useWindowWidthAndHeight } from '../../utils/windowSizeHook';
import { image1, image2, image3, image4, image5 } from '../../assets/benefits';
import {
  Container,
  InternalContainer,
  Title,
  BenefitsContainer,
  FirstRow,
  MobileFirstRow,
  SecondRow
} from './styles';

const BenefitsScreen: React.FC = () => {
  const dimensions = useWindowWidthAndHeight();
  const width = dimensions[0];
  const isMobile = width <= 1000;

  const Row = isMobile ? MobileFirstRow : FirstRow;
  return (
    <Container>
      <InternalContainer isMobile={isMobile}>
        <Title>Why Guider?</Title>
        <BenefitsContainer>
          <Row>
            <Benefit
              image={image1}
              title={'Get bite-sized content'}
              description={'So you can learn in just 3-5 minutes a day'}
            />
            <Benefit
              image={image2}
              title={'Set personalised goals'}
              description={'So that your journey to Allah is tailored to you'}
            />
          </Row>
          <SecondRow>
            <Benefit
              image={image3}
              title={'Track your progress'}
              description={'To build meaningful habits'}
            />
          </SecondRow>
          <Row>
            <Benefit
              image={image4}
              title={'Access authentic guidance'}
              description={'To transform yourself with knowledge you can trust'}
            />
            <Benefit
              image={image5}
              title={'Join a supportive community'}
              description={'For motivation to achieve your spiritual goals'}
            />
          </Row>
        </BenefitsContainer>
      </InternalContainer>
    </Container>
  );
};

export { BenefitsScreen };
