import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 0 76px 0 75px;
  background-color: #fdfbf5;
  max-height: 750px;
`;

export const MobileContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 10% 0 10%;
`;

export const LeftContainer = styled.div<{ isMobile: boolean }>`
  flex: 0.4;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isMobile ? 'center' : undefined)};
`;

export const LeftUpperContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: center;
  align-items: ${(props) => (props.isMobile ? 'center' : undefined)};
  flex-direction: column;
  height: 100%;
`;

export const LeftLowerContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isMobile ? 'center' : 'flex-start')};
  max-width: 100%;
`;

export const FeaturedOnText = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
  text-align: center;
  color: #1e293b;
`;

export const FeaturedOnIcons = styled.div<{ isMobile: boolean }>`
  margin: 20px 0 ${(props) => (props.isMobile ? '0 20px' : '30px 0')};
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 400px;
`;

export const FeaturedOnIconContainer = styled.a<{ isMobile: boolean }>`
  margin-right: ${(props) => (props.isMobile ? '20px' : '')};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33%;
`;

export const FeaturedOnIcon = styled.img``;

export const Title = styled.div<{ isMobile: boolean }>`
  margin-bottom: 24px;
  margin-top: ${(props) => (props.isMobile ? '24px' : 0)};
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: ${(props) => (props.isMobile ? '30px' : '60px')};
  line-height: ${(props) => (props.isMobile ? '40px' : '82px')};
  color: #1e293b;
`;

export const Description = styled.div<{ isMobile: boolean }>`
  margin-bottom: 40px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => (props.isMobile ? '17px' : '22px')};
  line-height: 30px;
  color: #667b99;
`;

export const DownloadLinksContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
`;

export const DownloadImage = styled.img`
  max-width: 100%;
`;

export const Button = styled.button<{ isMobile: boolean }>`
  align-items: center;
  padding: 12px 24px;
  margin: 0;
  background: #073d59;
  border-radius: 0px 8px 8px 0px;
  border: 1px solid #115f6a;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => (props.isMobile ? '15px' : '18px')};
  line-height: ${(props) => (props.isMobile ? '18px' : '22px')};
  color: #ffffff;
  cursor: pointer;
`;

export const ImageContainer = styled.div<{ isMobile: boolean }>`
  flex: 0.6;
  padding-top: ${(props) => (props.isMobile ? '40px' : '84px')};
  padding-left: ${(props) => (props.isMobile ? 0 : '84px')};
  display: flex;
  justify-content: center;
`;

export const Image = styled.img`
  display: inline-block;
  height: auto;
  max-height: -webkit-fill-available;
  max-width: 100%;
  min-width: min(600px, 100%);
`;
