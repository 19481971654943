export enum GoalsIds {
  PRAYERS = 'prayers',
  QURAN = 'quran',
  CONNECTION = 'connection',
  HABITS = 'habits',
  BELIEFS = 'beliefs',
  GOAL = 'goal'
}

export const PUBLIC_MAILCHIMP_URL =
  'https://theguiderapp.us21.list-manage.com/subscribe/post?u=a301bbf496dc4ebc0ad8a71ef&id=4e2e1980f3&f_id=002ccde1f0';

export const MAILCHIMP_SIGNUP_HERO_TAG = 232910;
export const MAILCHIMP_SIGNUP_SOCIAL_TAG = 233549;
export const MAILCHIMP_SIGNUP_MEET_GUIDER_TAG = 233550;
export const MAILCHIMP_PRACTICAL_GUIDER_1_TAG = 233545;
export const MAILCHIMP_PRACTICAL_GUIDER_2_TAG = 233546;
export const MAILCHIMP_PRACTICAL_GUIDER_3_TAG = 233547;
export const MAILCHIMP_PRACTICAL_GUIDER_4_TAG = 233548;

export const MAILCHIMP_NEWBRAND_TAG = 2988710;
export const MAILCHIMP_NEWBRAND_EMAIL_TAG = 2988891;
export const MAILCHIMP_NEWBRAND_TELEGRAM_TAG = 2988892;
export const MAILCHIMP_NEWBRAND_CUBE_TAG = 2988894;
export const MAILCHIMP_NEWBRAND_GMW_TAG = 2988895;
export const MAILCHIMP_NEWBRAND_SHAREDEMAIL_TAG = 2989095;

export const APP_IDS = {
  STAGING_APP_ID: 'guider-staging-kuuyy',
  INTERNAL_APP_ID: 'guider-internal-difnd',
  INTERNAL_TEST_APP_ID: 'guider-internal-test-yldos',
  PROD_APP_ID: 'guider-atlas-production-qoaqw'
};

export const instagramLink = 'https://www.instagram.com/theguiderapp/';
export const linkedInLink = 'https://www.linkedin.com/company/guiderapp/';
export const tiktokLink = 'https://www.tiktok.com/@theguiderapp';
