import React, { useEffect, useState } from 'react';
import { PageHOC } from '../../components';
import { ResetContainer, Title, Form, Label, SubmitButton, Text, ErrorText, Input } from './styles';
import { App as RealmApp } from 'realm-web';
import { useSearchParams } from 'react-router-dom';
import { APP_IDS } from '../../utils/constants';
import { TrackingService } from '../../utils/trackingService';

const ERRORS = {
  INVALID_URL: 'Malformed URL. Please make sure you have opened the correct confirmation link.',
  INVALID_PASS: 'Password is missing or is less than 6 characters',
  INVALID_PASS_CONFIRM: 'Password confirmation is invalid'
};

interface ResetPasswordParams {
  appId: string;
  token: string;
  tokenId: string;
}

const appIdShortcuts: { [key: string]: string } = {
  p: APP_IDS.PROD_APP_ID,
  s: APP_IDS.STAGING_APP_ID,
  i: APP_IDS.INTERNAL_APP_ID
};

const ResetPassword: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [pass, setPass] = useState<string>('');
  const [passConfirm, setPassConfirm] = useState<string>('');
  const [passwordResetText, setPasswordResetText] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    TrackingService.track('reset_password_page_opened');
  }, []);

  async function resetPassword({ appId, tokenId, token }: ResetPasswordParams) {
    if (appIdShortcuts[appId] != null) {
      appId = appIdShortcuts[appId];
    }
    try {
      const realm = RealmApp.getApp(appId);
      await realm.emailPasswordAuth.resetPassword({
        token: token,
        tokenId: tokenId,
        password: pass
      });
      setPasswordResetText('Password reset successful. Please log in to the Niyyah App.');
      TrackingService.track('reset_password_page_submit', { status: 'success' });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      TrackingService.track('reset_password_page_submit', { status: 'fail', error: e.error });
      setPasswordResetText(`Unable to reset password, error: ${e.error ?? ''}. Please try again`);
    }
  }

  useEffect(() => {
    if (
      searchParams.get('token') == null ||
      searchParams.get('tokenId') == null ||
      searchParams.get('appId') == null
    ) {
      setError(ERRORS.INVALID_URL);
    }
  }, [searchParams]);

  const onPasswordSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setError('');
    setPasswordResetText('');
    try {
      if (!pass || pass.length < 6) {
        setError(ERRORS.INVALID_PASS);
        return;
      } else if (!passConfirm || pass !== passConfirm) {
        setError(ERRORS.INVALID_PASS_CONFIRM);
        return;
      }
      const token = searchParams.get('token');
      const tokenId = searchParams.get('tokenId');
      const appId = searchParams.get('appId');
      setPasswordResetText('Resetting password. Please wait...');
      token && tokenId && appId && pass && passConfirm && resetPassword({ token, tokenId, appId });
    } catch (e: any) {
      TrackingService.track('reset_password_page_submit', { status: 'fail', error: e.error });
      setError('Error');
    }
  };

  return (
    <PageHOC showAction={false}>
      <ResetContainer>
        <Title>Niyyah - Password Reset</Title>
        {error === ERRORS.INVALID_URL ? (
          <ErrorText>{error}</ErrorText>
        ) : (
          <>
            <Form onSubmit={onPasswordSubmit}>
              <Label>
                Enter Password:
                <Input
                  placeholder={'Enter a new password'}
                  type="password"
                  value={pass}
                  onChange={(e) => setPass(e.target.value)}
                />
              </Label>
              <Label>
                Confirm Password:
                <Input
                  placeholder={'Confirm new password'}
                  type="password"
                  value={passConfirm}
                  onChange={(e) => setPassConfirm(e.target.value)}
                />
              </Label>
              <SubmitButton type="submit" value="Submit" />
            </Form>
            <Text>{passwordResetText}</Text>
            <ErrorText>{error}</ErrorText>
          </>
        )}
      </ResetContainer>
    </PageHOC>
  );
};

export { ResetPassword };
