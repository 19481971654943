export function scrollToTargetAdjusted(id: string) {
  const element = document.getElementById(id);
  const headerOffset = 200;
  if (element != null) {
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition =
      elementPosition !== 0 ? elementPosition + window.pageYOffset - headerOffset : 0;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }
}
