import React, { useState } from 'react';
import {
  Container,
  Header,
  CollapseText,
  CollapseElement,
  ButtonText,
  Arrow,
  CollapseTextContainer
} from './styles';
import { arrowUp, coloredArrowDown } from '../../assets/donation';
import './styles.css';

interface Props {
  title: string;
  text: JSX.Element;
}

const CollapseItem: React.FC<Props> = ({ text, title }) => {
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <Header
        style={{
          backgroundColor: open ? '#073d59' : 'white',
          border: open ? 'none' : '1px solid #e5e7eb'
        }}
        onClick={() => {
          setOpen(!open);
        }}>
        <ButtonText style={{ color: open ? 'white' : '#073D59' }}>{title}</ButtonText>
        <Arrow src={open ? arrowUp : coloredArrowDown} />
      </Header>
      <CollapseTextContainer open={open}>
        <CollapseElement isOpened={open}>
          <CollapseText>{text}</CollapseText>
        </CollapseElement>
      </CollapseTextContainer>
    </Container>
  );
};

export { CollapseItem };
