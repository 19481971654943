import styled from 'styled-components';

export const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
`;

export const PopupContent = styled.div`
  width: 75%;
  max-width: 600px;
  background: #fdfbf5;
  padding: 0 0 20px 0;
  border-radius: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
`;

export const PopupNoButton = styled.button`
  background: none;
  color: #667b99;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 15px;
  text-decoration: underline;
`;

export const PopupImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 16px;
`;

export const PopupTitle = styled.h2`
  color: #1e293b;
  font-size: 26px;
  margin-top: 15px;
  margin-bottom: 0;
  padding: 0 20px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const PopupText = styled.h3`
  color: #667b99;
  font-size: 18px;
  padding: 0 20px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const PopupInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 100%;
  position: relative;
`;

export const PopupInput = styled.input`
  padding: 16px 32px;
  border-radius: 50px 0px 0px 50px;
  border: 0.5px solid #073d59;
  background: #fff;
  z-index: 1;
  width: 200px;

  :focus {
    outline: none;
  }

  ::placeholder {
    font-size: 12px;
    font-family: Mulish;
    font-style: italic;
    color: #667b99;
  }

  @media (max-width: 768px) {
    padding: 8px 8px;

    ::placeholder {
      font-size: 10.5px;
      font-family: Mulish;
      font-style: italic;
      color: #667b99;
    }
  }
`;

export const InputButton = styled.button`
  padding: 12px 32px;
  border-radius: 0px 50px 50px 0px;
  border: 0.5px solid #073d59;
  background: #073d59;
  color: #fff;
  cursor: pointer;

  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  z-index: 2;

  @media (max-width: 768px) {
    font-size: 10px;
    padding: 9px 12px;
  }
`;

export const ConfirmationContainer = styled.div`
  width: 75%;
  max-width: 600px;
  background: #fdfbf5;
  padding: 0 0 20px 0;
  border-radius: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ConfirmationImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 16px;
`;

export const OkButton = styled.button`
  display: flex;
  width: 180px;
  margin: auto;
  padding: 16px;

  justify-content: center;
  align-items: center;

  border-radius: 8px;
  border-left: 1px solid #073d59;
  background: #073d59;
  cursor: pointer;

  font-family: Mulish;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 12px;
    width: 100px;
    padding: 8px;
  }
`;
