import React from 'react';

const Tiktok = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7398 23.0067C18.8243 23.0067 23.7745 18.0567 23.7745 11.972C23.7745 5.8875 18.8245 0.9375 12.7398 0.9375C6.65508 0.9375 1.70508 5.8875 1.70508 11.972C1.70508 18.0567 6.65532 23.0067 12.7398 23.0067ZM12.7398 1.4175C18.5598 1.4175 23.2945 6.15222 23.2945 11.972C23.2945 17.792 18.5598 22.5267 12.7398 22.5267C6.9198 22.5267 2.18508 17.792 2.18508 11.972C2.18508 6.15222 6.9198 1.4175 12.7398 1.4175Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4848 6C12.4251 6 12.3679 6.02529 12.3257 6.07029C12.2835 6.1153 12.2598 6.17635 12.2598 6.24V14.4C12.2598 15.0624 11.7558 15.6 11.1348 15.6C10.5138 15.6 10.0098 15.0624 10.0098 14.4C10.0098 13.7376 10.5138 13.2 11.1348 13.2C11.1944 13.2 11.2517 13.1747 11.2939 13.1297C11.3361 13.0847 11.3598 13.0237 11.3598 12.96V11.04C11.3598 10.9763 11.3361 10.9153 11.2939 10.8703C11.2517 10.8253 11.1944 10.8 11.1348 10.8C9.27177 10.8 7.75977 12.413 7.75977 14.4C7.75977 16.3872 9.27177 18 11.1348 18C12.9975 18 14.5098 16.3872 14.5098 14.4V10.4386C15.0046 10.6775 15.5416 10.8007 16.0848 10.8H16.5348C16.5944 10.8 16.6517 10.7747 16.6939 10.7297C16.7361 10.6847 16.7598 10.6237 16.7598 10.56V8.64C16.7598 8.57635 16.7361 8.5153 16.6939 8.47029C16.6517 8.42529 16.5944 8.4 16.5348 8.4H16.0848C15.2156 8.4 14.5098 7.64712 14.5098 6.72V6.24C14.5098 6.17635 14.4861 6.1153 14.4439 6.07029C14.4017 6.02529 14.3444 6 14.2848 6H12.4848ZM12.7098 6.48H14.0598V6.72C14.0598 7.91208 14.9672 8.88 16.0848 8.88H16.3098V10.32H16.0848C15.4703 10.32 14.8941 10.1448 14.3975 9.83784C14.3633 9.81672 14.3245 9.80558 14.2849 9.80555C14.2454 9.80552 14.2066 9.81659 14.1723 9.83766C14.1381 9.85874 14.1097 9.88906 14.0899 9.92557C14.0701 9.96209 14.0597 10.0035 14.0598 10.0457V14.4C14.0598 16.122 12.7491 17.52 11.1348 17.52C9.52039 17.52 8.20977 16.122 8.20977 14.4C8.20977 12.7589 9.40047 11.412 10.9098 11.2891V12.737C10.147 12.8537 9.55977 13.5542 9.55977 14.4002C9.55977 15.3271 10.2656 16.0802 11.1348 16.0802C12.0039 16.0802 12.7098 15.3271 12.7098 14.4002V6.48Z"
        fill="white"
      />
    </svg>
  );
};
export default Tiktok;
