/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { useWindowWidthAndHeight } from '../../utils/windowSizeHook';
import { TrackingService } from '../../utils/trackingService';
import androidDownloadImg from '../../assets/android.png';
import iosDownloadImg from '../../assets/ios.png';
import {
  Container,
  UpperContainer,
  Title,
  SubTitle,
  Text,
  HiddenText,
  ReadMoreButton,
  WishListContainer,
  RightSide,
  DownloadText,
  DownloadLinksContainer,
  DownloadImage,
  TryText
} from './styles';

const MeetGuiderScreen: React.FC = () => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const onExpand = () => {
    setExpanded(!expanded);
  };

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [width, height] = useWindowWidthAndHeight();
  const isMobile = width <= 1000;

  return (
    <Container>
      <UpperContainer isMobile={isMobile}>
        <Title>Meet Guider</Title>
        <SubTitle>Assalamu alaikum (peace be upon you)</SubTitle>
        <Text style={{ marginBottom: expanded ? '0' : '20px' }}>
          My name is Guider and I am the daily guide for busy Muslims. I’d like to share my story
          with you.
          <br />
          <br />
          The team that built me is Muslim just like you. They were drifting away from their faith
          as life became busier with responsibilities. When they looked for solutions they felt
          frustrated.
          <br />
        </Text>
        <HiddenText style={{ display: expanded ? 'block' : 'none' }}>
          <br />
          The internet overwhelmed them with unstructured content. Youtube videos only gave them
          temporary imaan boosts and googling was stressful. Social media posts weren’t cutting it
          either. What was authentic? What type of content would have a practical impact? And how
          could they stop getting distracted by funny cat videos?
          <br />
          <br />
          So they enrolled onto courses. But they were time-consuming, expensive, and ultimately
          their lessons were forgotten.
          <br />
          <br />
          <span style={{ fontWeight: 'bold' }}>
            Yet, the desire to build a closer connection to Allah and become better Muslims was
            still strong.
          </span>
          <br />
          <br />
          That’s when they made me. InshaAllah, I’m the go-to education app for Muslims, providing
          transformational and relatable content without the overwhelm. I turn your most important
          spiritual goals into bite-sized checklists so you can make consistent daily progress. And
          I’ll help you create habits that encourage mindfulness of Allah and build a deeper
          connection with Him.
          <br />
          <br />
          But that’s not all!
          <br />
          <br />
          In the future, we’ll build a supportive community of like-minded Muslims to motivate you
          on your journey.
          <br />
          <br />
          <span style={{ fontWeight: 'bold' }}>I’m not just an app, I’m a movement!</span>
          <br />
          <br />
          Download me today!
          <br />
          <br />
          Sincerely,
          <br />
          Guider
        </HiddenText>
        <ReadMoreButton
          onClick={() => {
            expanded
              ? TrackingService.track('meet_guider_read_less_clicked')
              : TrackingService.track('meet_guider_read_more_clicked');
            onExpand();
          }}>
          {expanded ? 'Read less...' : 'Read more...'}
        </ReadMoreButton>
      </UpperContainer>
      <WishListContainer isMobile={isMobile}>
        <RightSide isMobile={isMobile}>
          <DownloadText>Download Guider</DownloadText>
          <DownloadLinksContainer>
            <a
              href={
                'https://play.google.com/store/apps/details?id=com.guider.guidermobile&hl=en&pli=1'
              }
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                TrackingService.track('meet_guider_android_download_button_clicked');
              }}>
              <DownloadImage src={androidDownloadImg} />
            </a>
            <a
              href={'https://apps.apple.com/gb/app/guider-the-app-for-muslims/id6446500974'}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                TrackingService.track('meet_guider_ios_download_button_clicked');
              }}>
              <DownloadImage src={iosDownloadImg} />
            </a>
          </DownloadLinksContainer>
          <TryText>Try for free today!</TryText>
        </RightSide>
      </WishListContainer>
    </Container>
  );
};

export { MeetGuiderScreen };
